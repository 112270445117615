import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const appTheme = createTheme({
  breakpoints: {
    values: {
      xxs: 0,
      xs: 400,
      s: 550, // phone
      sm: 744, // tablets
      md: 950, // small laptop
      lg: 1200, // desktop
      xl: 1440, // large screens
    },
  },
  global: {
    headerHeight: {
      xxs: 60,
      s: 70,
      sm: 80,
      md: 80,
      lg: 80,
      xl: 80,
    },
    drawerWidth: 600,
    mobileFooterHeight: 80,
    btnBorderRadius: "8px",
  },
  spacing: [0, 4, 8, 16, 32, 48, 64],
  typography: {
    fontSizes: {
      xsm: 12,
      sm: 14,
      m: 16,
      l: 22,
      xl: 26,
      xxl: 34,
    },
    allVariants: {
      fontFamily: "Nunito Sans, sans-serif",
      textTransform: "none",
      fontSize: 16,
      color: "#222222",
    },
    body: {
      fontSize: 16,
    },
    bodyBold: {
      fontSize: 16,
      fontWeight: "bold",
    },
    body2: {
      fontSize: 14,
    },
    subtitle1: {
      fontSize: 22,
      fontWeight: 800,
      lineHeight: "26px",
    },
    subtitle2: {
      fontSize: 14,
    },
    small: { fontSize: 14 },
    smallBold: {
      fontSize: 14,
      fontWeight: "bold",
    },
    xSmall: { fontSize: 12 },
    xSmallBold: {
      fontSize: 12,
      fontWeight: "bold",
    },
    h5: {
      fontWeight: 500,
    },
    h6: {
      fontWeight: 500,
    },
    formError: {
      fontSize: 14,
      fontWeight: "bold",
      color: "#B32505",
    },
  },
  palette: {
    mode: "light",
    primary: {
      light: "#F7F7F7",
      main: "#DDDDDD",
      dark: "#717171",
    },
    refus: {
      light: "#dcedc8",
      main: "#7cb342",
      dark: "#33691e",
    },
    secondary: {
      light: "#d9f09b",
      main: "#666600",
      dark: "#404001",
    },
    btn_action: {
      main: "#CD5C5C",
      hover: "#A52A2A",
    },
    btn_add: {
      main: "#87CEEB",
      hover: "#4682B4",
    },
    error: { main: "#B32505" },
    warning: { main: "#E07912" },
    info: { main: "#008A05" },
    neutral: {
      main: "#222222",
      contrastText: "#fff",
    },
    white: "#fff",
    black: "#222222",
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          subtitle1: "div",
          body: "span",
          bodyBold: "span",
          small: "span",
          smallBold: "span",
        },
      },
    },
    MuiStack: {
      defaultProps: {
        sx: {
          px: 2,
          py: 1,
        },
        spacing: 2,
        direction: "row",
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "none",
      },
      styleOverrides: {
        root: {
          color: (theme) => theme.palette.neutral.main,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
      variants: [
        {
          props: { variant: "rounded" },
          style: {
            borderRadius: 28,
          },
        },
        {
          props: { variant: "rounded", color: "success" },
          style: {
            fontSize: "22px",
            color: "white",
            backgroundColor: "#7cb342",
            padding: "10px 60px",
            ":hover": {
              backgroundColor: "#7cb342",
            },
          },
        },
        {
          props: { variant: "containedAction" },
          style: {
            backgroundColor: "#666600",
            color: "white",
            borderRadius: (theme) => theme.global.btnBorderRadius,
            "&:hover": {
              backgroundColor: "#666600",
            },
          },
        },
      ],
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        tooltip: {
          background: (theme) => theme.palette.primary.main,
        },
        arrow: {
          color: (theme) => theme.palette.primary.main,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: (theme) => theme.palette.primary.main,
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: ({ theme }) => ({
          "& .MuiBadge-badge": {
            backgroundColor: theme.palette.btn_action.main,
            color: theme.palette.white,
            right: -5,
            top: 13,
            padding: "2px 4px",
          },
        }),
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          "&.Mui-completed": {
            color: theme.palette.secondary.main,
            ".MuiStepIcon-text": {
              fill: "white",
            },
          },
          "&.Mui-active": {
            color: theme.palette.secondary.main,
            ".MuiStepIcon-text": {
              fill: "white",
            },
          },
        }),
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: ({ theme }) => ({
          "&.Mui-completed": {
            color: theme.palette.secondary.main,
            ".MuiStepIcon-text": {
              fill: "white",
            },
          },
          "&.Mui-active": {
            color: theme.palette.secondary.main,
            ".MuiStepIcon-text": {
              fill: "white",
            },
          },
        }),
      },
    },
  },
});

function AppThemeProvider(prop) {
  return <ThemeProvider theme={appTheme}> {prop.children} </ThemeProvider>;
}

export default AppThemeProvider;
