import { createContext, useContext, useState } from 'react';
import { useTheme } from '@mui/styles';

export const MapContext = createContext({});
export const useMapContext = () => useContext(MapContext);

export function MapProvider({ children }) {
  const theme = useTheme();

  const [selectedItem, setSelectedItem] = useState(null);
  const [hoverItem, setHoverItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const value = {
    selectedItem,
    setSelectedItem,
    hoverItem,
    setHoverItem,
    isLoading,
    setIsLoading,
  };

  return <MapContext.Provider value={value}>{children}</MapContext.Provider>;
}
