import { createActions } from "redux-actions";

import {
  HANDLE_CLOSE_MODAL,
  IS_MODAL_OPEN,
  RENDER_MODAL_CONTENT,
} from "./actionTypes";

export const { isModalOpen, handleCloseModal, renderModalContent } =
  createActions(IS_MODAL_OPEN, HANDLE_CLOSE_MODAL, RENDER_MODAL_CONTENT);
