import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {useSelector} from "react-redux";
import {useTheme} from "@mui/styles";
import {displayOnBigMobile, displayOnDesktop, displayOnTablet, flexBetween,} from "../../styles/themes/commonStyles";
import LocationSearch from "../LocationSearch/LocationSearch";
import Logo from "./Logo";
import CartBadge from "./CartBadge";
import {getCart} from "../../store/modules/order/selectors";
import {useUIContext} from "../../context/UIContext";
import WithHomeLink from "../Navigation/WithHomeLink";
import {useTranslation} from "react-i18next";
import CustomLink from "../Shared/CustomLink";

function Header() {
  const { t } = useTranslation("general");
  const { showSearchBox, isHomePage, setCartOpen } = useUIContext();
  const theme = useTheme();
  const cart = useSelector(getCart);

  const handleCartBadgeClick = () => {
    setCartOpen(true);
  };

  if (showSearchBox === undefined) {
    return null;
  }

  return (
    <Box
      sx={{
        borderBottom: showSearchBox ? "1px solid #ddd" : "none",
      }}
    >
      <Container disableGutters maxWidth={!isHomePage ? "lg" : false}>
        <Box
          sx={{
            ...flexBetween,
            alignItems: "center",
            height: theme.global.headerHeight,
            px: { xxs: "16px", sm: "40px", md: "40px", xl: "80px"},
          }}
        >
          <Box
            sx={{
              ...flexBetween,
              ...displayOnBigMobile,
            }}
          >
            <WithHomeLink
              sx={{
                mr: "8px",
              }}
            >
              <Logo />
            </WithHomeLink>
          </Box>
          {showSearchBox && (
            <Box>
              <LocationSearch />
            </Box>
          )}
          <Box
            sx={{
              ...flexBetween,
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                ...displayOnDesktop,
                mr: "10px",
              }}
            >
              <CustomLink route="help">
                {t("header.help")}
              </CustomLink>
            </Box>
            {showSearchBox && (
              <Box sx={{ ...displayOnTablet }}>
                <CartBadge
                  handleClick={handleCartBadgeClick}
                  badgeContent={cart && cart.length}
                />
              </Box>
            )}
          </Box>
          {/* <Box sx={displayOnLargeDesktop}>
                <MobileSearch />
              </Box> */}
        </Box>
      </Container>
    </Box>
  );
}

export default React.memo(Header);
