import styled from 'styled-components';
import { COLORS } from '../../styles/colors';

export const RefugeContainer = styled.div`
  width: 100% !important;
  height: 100% !important;
  margin-bottom: 12px !important;
`;

export const ListItemContainer = styled.div`
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  -webkit-justify-content: space-between !important;
  justify-content: space-between !important;
  height: 100% !important;
  position: relative !important;
 
  --dls19-brand-color: #FF385C !important;
  
  @media (min-width: 570px) { 
    a {
      display: flex;
      width:100%
    }
  }
`;

export const RefugeImageContainer = styled.div`
  flex-shrink: 0;
  width: calc(100% - 0%)
  overflow: hidden;
  border-radius: 8px;
  @media (min-width: 570px) { 
    width: 242px !important;
    height: 162px !important;
  }
`;

export const RefugeImage = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const RefugePhoto = styled.img`
  width: 100%;
  object-fit: cover;
`;

export const RefugeInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #FFF;
  height: 162px !important;
  
  @media (min-width: 570px) { 
    margin-top: 0 !important;
    margin-left: 12px !important;
  }
`;

export const CategoryItemWithStatusWrapper = styled.div`
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    /* justify-content: space-between; */
    -webkit-align-items: flex-end;
    -webkit-box-align: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end;
`;

export const ServicesItemWrapper = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${COLORS.refusTextGrey}!important;
`;

export const NameItemWrapper = styled.div`
  
`;

export const NameItem = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.refusTextBlack}; 
  max-height: 24px !important;
`;

export const RefugeImageItems = styled.div`
  display: flex;
  width: 100%;
  position: absolute;
  top: 80px;
  @media (max-width: 570px) {
    top: 160px;
  }
`;

export const RefugeImageInfoContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: left;
`;

export const Icon = styled.img`
  width: 20px;
  height: 20px;
  border-color: #FFF;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
`;

export const LocationItem = styled.h6`
  color: #FFF;
  margin: 0 0 0 5px;
`;

export const BedItem = styled.h6`
  color: #FFF;
  margin: 0 0 0 5px;
`;

export const UrlAddressItem = styled.h6`
  margin: 2px;  
  color: #000;
  word-break: break-all;
`;

export const AddressItem = styled.h6`
  margin: 2px;
  color: #000;
  font-weight: 400;
`;

export const PhoneItem = styled.h6`
  margin: 2px;
  color: #000;
  font-weight: 400;
`;
