export const isObject = (toValidate) => !!(toValidate && typeof toValidate === 'object');

export const isString = (toValidate) => typeof toValidate === 'string';

export const isLengthGreaterThan = (length) => (toValidate) => toValidate.length > length;

export const isLengthEqualThan = (length) => (toValidate) => toValidate.length === length;

export const isDateBeforeThan = (date) => (toValidate) => toValidate.getTime() < date.getTime();

export const isDateAfterThan = (date) => (toValidate) => toValidate.getTime() > date.getTime();

export const isDateSameThan = (date) => (toValidate) => toValidate.getTime() === date.getTime();
