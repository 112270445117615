import moment from 'moment';
import { sumObjs } from '../../lib/sumObjs';

export const calculateNights = (stay) => moment(stay.to).startOf('day').diff(moment(stay.from).startOf('day'), 'days');

export const calculateCartPrice = (cart) => cart.map(
  (stay) => stay.products.reduce((total, product) => {
    let nights = calculateNights(stay);
    nights = ((nights === 0) ? nights += 1 : nights);
    return {
      total: total.total + (product.price * product.quantity * nights),
      // eslint-disable-next-line max-len
      deposit: total.deposit + ((product.deposit) ? parseFloat(product.deposit, 10) * product.quantity * nights : 0),
    };
  }, { total: 0, deposit: 0 }),
);

export const calculateOrderPrice = (cart) => {
  if (cart.length === 0) return { total: 0, deposit: 0 };
  if (cart.length === 1) return calculateCartPrice(cart)[0];
  return sumObjs(...calculateCartPrice(cart));
};

export const orderService = Object.freeze({
  calculateOrderPrice,
  calculateCartPrice,
  calculateNights,
});
