import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { displayOnLargeDesktop, flexBetween } from "Styles/themes/commonStyles";
import { LogoImage } from "Layout/Header/styles";
import logo from "Assets/images/logo-refusonline.png";

function Logo() {
  return (
    <Box
      sx={{
        ...flexBetween,
        alignItems: "center",
      }}
    >
      <LogoImage src={logo} alt="logo" />
      <Typography
        sx={{
          ...displayOnLargeDesktop,
          marginLeft: "0.5rem",
          color: (theme) => theme.palette.secondary.main,
          fontSize: "20px",
          fontWeight: "bold",
        }}
        component="span"
      >
        refusonline
      </Typography>
    </Box>
  );
}

export default React.memo(Logo);
