import { createActions } from 'redux-actions';

import {
  FETCH_CONFIG_APP,
  SET_CONFIG_APP,
} from './actionTypes';

export const {
  fetchConfigApp,
  setConfigApp,
} = createActions(
  FETCH_CONFIG_APP,
  SET_CONFIG_APP,
);
