import React from "react";
import { useFormikContext } from "formik";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import CustomerDetails from "./CustomerDetails";
import { useUIContext } from "../../../context/UIContext";
import ProductList from "./ProductList";

export default function ReviewOrder() {
  const { values: formValues } = useFormikContext();
  const { isMobile } = useUIContext();

  return (
    <Box
      sx={{
        paddingTop: "24px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={4}>
          <CustomerDetails formValues={formValues} />
        </Grid>
        <Grid item xs={12} sm={8} md={8}>
          <ProductList />
        </Grid>
      </Grid>
    </Box>
  );
}
