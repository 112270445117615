/* eslint-disable no-undef,class-methods-use-this,no-console */
import moment from 'moment';

class StateLoader {
  loadState() {
    try {
      const serializedState = localStorage.getItem('https://refusonline.com:state');

      if (serializedState === null) {
        return this.initializeState();
      }

      const currentState = JSON.parse(serializedState);
      return {
        ...currentState,
        refuges: {
          ...currentState.refuges,
          filters: {
            ...currentState.refuges.filters,
            peopleNumber: currentState.refuges.filters.peopleNumber,
            startDate: moment(currentState.refuges.filters.startDate),
            endDate: moment(currentState.refuges.filters.endDate),
          },
        },
      };
    } catch (err) {
      return this.initializeState();
    }
  }

  saveState(state) {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('https://refusonline.com:state', serializedState);
    } catch (err) {
      console.error({ err });
    }
  }

  initializeState() {
    return {
      refuges: {
        list: [],
        refuge: null,
        filters: {
          peopleNumber: 1,
          startDate: moment(),
          endDate: moment().add(1, 'day'),
        },
        services: null,
      },
      order: {
        cart: [],
        customer: null,
        orderId: null,
      },
      config: {
        configApp: {
          status: 'idle',
          data: null,
          error: null,
        },
      },
      ui: {
        errorMessage: '',
        mobileBottomNavShowing: true,
      },
      language: {
        locale: 'ca',
      },
    };
  }
}

export default StateLoader;
