import React, { useEffect } from "react";
import { useTheme } from "@mui/styles";
import useStyles from "./checkout.styles";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import { Step, StepLabel, Stepper } from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import ClearIcon from "@mui/icons-material/Clear";
import {
  colFlex,
  flexBetween,
  flexCenter,
  flexEnd,
  rowFlex,
} from "../../styles/themes/commonStyles";
import { useUIContext } from "../../context/UIContext";
import checkoutValidationSchema from "./FormModel/checkoutValidationSchema";
import { useCheckoutContext } from "../../context/CheckoutContext";
import { usePage } from "../../hooks/usePage";
import SimpleBackdrop from "../../components/Shared/SimpleBackdrop";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { isDevelopment } from "../../config/utils";
import CustomModal from "../../components/Modal";
import { useSelector } from "react-redux";
import { selectModal } from "../../store/modules/modal/selectors";
import WithHomeLink from "../../components/Navigation/WithHomeLink";
import { useTranslation } from "react-i18next";
import checkoutFormModel from "./FormModel/checkoutFormModel";
import checkoutInitialValues from "./FormModel/checkoutInitialValues";
import { useMountEffect } from "../../hooks/useMountEffect";

function CheckoutPage({ screen, showSearch }) {
  usePage(screen);
  const { setShowSearchBox } = useUIContext();
  useMountEffect(() => setShowSearchBox(showSearch));

  const navigate = useNavigate();
  const theme = useTheme();
  const classes = useStyles();

  const modal = useSelector(selectModal);
  const { isMobile, setShowSearch } = useUIContext();

  const {
    formId,
    activeStep,
    handleStepCompletion,
    handleBack,
    generateStepData,
    skipTPV,
    setSkipTPV,
    onSubmit,
    isModalOpen,
    handleCloseModal,
    renderModalContent,
  } = useCheckoutContext();

  const { component, steps } = generateStepData(activeStep);

  const { t } = useTranslation("checkout");
  const formModel = checkoutFormModel(t);

  const currentValidationSchema = checkoutValidationSchema(t, formModel)[
    activeStep
  ];
  const formInitialValues = checkoutInitialValues(formModel)[activeStep];

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, [activeStep]);

  const handleSubmit = (values, actions) => {
    if (activeStep === 2) {
      console.log("onSubmit");
      onSubmit();
    } else {
      handleStepCompletion(values, actions);
    }
  };

  const stepLabels = Object.values(steps).map((label, index) => {
    return (
      <Step key={label}>
        <StepLabel
          className={index === activeStep ? classes.activeStepLabel : undefined}
        >
          {label}
        </StepLabel>
      </Step>
    );
  });

  const isLastStep = activeStep === Object.values(steps).length - 1;

  const toggleSkipTPV = () => {
    setSkipTPV(!skipTPV);
  };

  return (
    <Container disableGutters maxWidth={false}>
      {!isLastStep && (
        <Box
          sx={{
            borderBottom: "1px solid #ddd",
          }}
        >
          <Container disableGutters maxWidth="lg">
            <Box sx={colFlex} className={classes.header}>
              <Box
                sx={{
                  ...rowFlex,
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <div className={classes.left}>
                  <WithHomeLink>
                    <ClearIcon sx={{ fontSize: 20 }} />
                  </WithHomeLink>
                </div>
                <div className={classes.center}>
                  <h1 className={classes.checkoutHeaderTitle}>{t("title")}</h1>
                </div>
              </Box>
              <Stepper
                activeStep={activeStep}
                className={classes.stepper}
                alternativeLabel={isMobile}
              >
                {stepLabels}
              </Stepper>
            </Box>
          </Container>
        </Box>
      )}
      <Container disableGutters maxWidth="lg">
        <Box
          sx={{
            px: "24px",
            [theme.breakpoints.up("sm")]: {
              px: "40px",
              py: "16px",
            },
          }}
        >
          <Paper className={classes.paper}>
            <>
              <Formik
                initialValues={formInitialValues}
                validationSchema={currentValidationSchema}
                onSubmit={handleSubmit}
              >
                {({ values, isSubmitting }) => (
                  <Form id={formId}>
                    {isSubmitting && <SimpleBackdrop isOpen />}
                    {component}
                    <Box
                      sx={{
                        ...flexBetween,
                        marginTop: "24px",
                      }}
                    >
                      <Box sx={flexCenter}>
                        {activeStep !== 0 && !isLastStep && (
                          <Button
                            variant="text"
                            onClick={handleBack}
                            sx={{
                              borderRadius: theme.global.btnBorderRadius,
                              color: theme.palette.black,
                              borderColor: theme.palette.black,
                            }}
                          >
                            {t("button.back")}
                          </Button>
                        )}
                      </Box>
                      <Box sx={flexEnd}>
                        {!isLastStep && (
                          <Button
                            variant="containedAction"
                            type="submit"
                            endIcon={<ArrowForwardIcon />}
                          >
                            {activeStep === 0
                              ? t("button.go_to_revisa")
                              : t("button.go_to_payment")}
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </Form>
                )}
              </Formik>
            </>
            {isDevelopment && (
              <Button onClick={toggleSkipTPV}>
                {skipTPV ? "Enable TPV Payment" : "Skip TPV Payment"}
              </Button>
            )}
          </Paper>
        </Box>
      </Container>
      <CustomModal
        isOpen={modal.isModalOpen}
        onCloseModal={modal.handleCloseModal}
        renderContent={modal.renderModalContent}
      />
    </Container>
  );
}

export default React.memo(CheckoutPage);
