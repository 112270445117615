import { get, post, put } from '../../../lib/restClient';

export const fetchAvailability = async ({
  params, from, to, peopleNumber,
}) => get(`/sites/availability?from=${from}&to=${to}&guests=${peopleNumber}`, params);

export const fetchSiteAvailability = async ({
  params, from, to, peopleNumber,
}) => get(`/sites/${params.id}/availability?from=${from}&to=${to}&guests=${peopleNumber}`, params);

export const fetchSiteAvailabilityBySlug = async ({
  params, from, to, peopleNumber,
}) => get(`/sites/${params.slug}/availability?from=${from}&to=${to}&guests=${peopleNumber}`, params);

export const fetchSiteProducts = async ({
  params, refugeId, from, to,
}) => get(`/sites/${refugeId}/products?from=${from}&to=${to}`, params);

export const sendCartData = async (body) => post('/orders', body);

export const putOrder = async (body) => put('/orders', body);
