import { useCallback, useEffect, useRef, useState } from "react";

const useScrollGoingDown = () => {
	const prevScrollY = useRef(0);
	const [goingDown, setGoingDown] = useState(false);
	const scrollRef = useRef(null);
	let currentScrollY = null;

	const handleScroll = useCallback(() => {
		if (scrollRef.current) {
			currentScrollY = scrollRef.current.scrollTop;
			if (prevScrollY.current < currentScrollY) {
				setGoingDown(true);
			}
			if (prevScrollY.current > currentScrollY) {
				setGoingDown(false);
			}

			prevScrollY.current = currentScrollY;
		}
	}, [])

	return [scrollRef, goingDown, handleScroll];
};

export default useScrollGoingDown;
