import React from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import { FormHelperText, TextareaAutosize, TextField } from '@mui/material';

export default function TextareaField(props) {
  const { errorText, ...rest } = props;
  const [field, meta] = useField(props);

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }
  }

  return (
    <TextField
      variant="standard"
      multiline
      aria-label="empty textarea"
      minRows={4}
      error={meta.touched && meta.error && true}
      helperText={_renderHelperText()}
      {...field}
      {...rest}
    />
  );
}
