import { handleActions } from "redux-actions";
import moment from "moment";

import {
  appendForfaitsToCart,
  appendRefugeToCart,
  placeOrderKoAction,
  placeOrderOkAction,
  removeCartItem,
  resetOrder,
  setBookingId,
  setCustomerData,
  setOrderId,
  setTpvForm,
  setTpvId,
  updatePickedForfaits,
} from "./actions";

export const defaultState = {
  cart: [],
  customer: {
    nif: "36525468L",
    firstName: "lluis",
    lastName: "rusca",
    email: "lluis1@gmail.com",
    phone: "234234",
    address1: "asdasd",
    city: "asdasd",
    zipcode: "08233",
    country: "cat",
    sortida: "des de casa",
    comments:
      "asdas jashdg askjhfg daksh fkad f\\n\\r\\n\\r\\n1 Tarifa Normal Adults",
    acceptDataAcceptance: true,
  },
  forfaits: null,
  orderId: null,
  bookingId: null,
  operId: null,
  loading: false,
  error: null,
  tpvForm: null,
};

function insertNotInDateRangeItem(array, payload) {
  const stay = payload.refuge;
  // Convert new stay dates to moment objects
  const newStayStartDate = moment(stay.from);
  const newStayEndDate = moment(stay.to);

  const isNotOverlapping = array.every((stay) => {
    const existingStayStartDate = moment(stay.from);
    const existingStayEndDate = moment(stay.to);

    // Check if the new stay dates are completely outside the range of existing stay
    const isBeforeExistingStay = newStayEndDate.isBefore(existingStayStartDate);
    const isAfterExistingStay =
      newStayStartDate.isSameOrAfter(existingStayEndDate);

    // If the new stay dates are outside the range, return true, indicating no overlap
    return isBeforeExistingStay || isAfterExistingStay;
  });

  // If there's no overlapping dates, add the new stay to the cart
  if (isNotOverlapping) {
    return [...array, stay];
  } else {
    // If there's overlap, return the original array without appending the new stay
    return array;
  }
}

function insertNotRepeatedItemWithIndex(array, payload) {
  const filtered = array.filter((guest) => guest.id !== payload.refuge.id);
  return [
    ...filtered.slice(0, payload.index),
    payload.refuge,
    ...filtered.slice(payload.index),
  ];
}

export default handleActions(
  {
    [updatePickedForfaits]: (state, { payload }) => ({
      ...state,
      forfaits: {
        ...state.forfaits,
        ...payload.forfaits,
      },
    }),
    [appendForfaitsToCart]: (state, { payload }) => ({
      ...state,
      cart:
        state.cart.length > 0
          ? insertNotInDateRangeItem(state.cart, payload)
          : insertNotInDateRangeItem(state.cart, payload),
    }),
    [appendRefugeToCart]: (state, { payload }) => ({
      ...state,
      cart: insertNotInDateRangeItem(state.cart, payload),
    }),
    [removeCartItem]: (state, { payload }) => ({
      ...state,
      cart: [...state.cart.slice(0, payload), ...state.cart.slice(payload + 1)],
    }),
    [setCustomerData]: (state, { payload: { customer } }) => ({
      ...state,
      customer: {
        ...state.customer,
        ...customer,
      },
    }),
    [setOrderId]: (state, { payload: { orderId } }) => ({
      ...state,
      orderId,
    }),
    [resetOrder]: (state) => ({
      ...state,
      cart: [],
      forfaits: [],
      customer: {},
      orderId: null,
    }),
    [setBookingId]: (state, { payload: { bookingId } }) => ({
      ...state,
      bookingId,
    }),
    [setTpvId]: (state, { payload: { operId } }) => ({
      ...state,
      operId,
    }),
    [placeOrderOkAction]: (state) => ({
      ...state,
      loading: false,
      error: null,
    }),
    [placeOrderKoAction]: (state, { payload: { error } }) => ({
      ...state,
      loading: false,
      error,
    }),
    [setTpvForm]: (state, { payload: { tpvForm } }) => ({
      ...state,
      tpvForm,
    }),
  },
  defaultState
);
