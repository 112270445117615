import React from 'react';
import ReactLoading from 'react-loading';
import Box from '@mui/material/Box';
import { makeStyles, useTheme } from '@mui/styles';

function BubblesLoading(props) {
  const theme = useTheme();

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10vh',
      }}
    >
      <ReactLoading
        type="bubbles"
        color={theme.palette.secondary.main}
        height={200}
        width={100}
      />
    </Box>
  );
}

export default React.memo(BubblesLoading);
