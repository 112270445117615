import React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { displayOnMobile } from "../../styles/themes/commonStyles";
import MobileBottomNav from "../../components/Layout/MobileBottomNav";
import Box from "@mui/material/Box";
import { usePage } from "../../hooks/usePage";
import { useUIContext } from "../../context/UIContext";
import { useMountEffect } from "../../hooks/useMountEffect";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    textAlign: "center",
    padding: "100px",
    backgroundColor: "#fff", // Background color
    borderRadius: "8px",
  },
  title: {
    fontSize: "24px",
    marginBottom: "16px",
  },
  message: {
    fontSize: "18px",
  },
  emailLink: {
    color: "blue",
    textDecoration: "underline",
  },
};

const ContactPage = ({ screen, showSearch }) => {
  usePage(screen);
  const { setShowSearchBox } = useUIContext();
  useMountEffect(() => setShowSearchBox(showSearch));

  return (
    <>
      <Container style={styles.container} maxWidth="sm">
        <Paper elevation={0} style={styles.paper}>
          <Typography variant="h4" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="body1" paragraph>
            If you have any questions or need assistance, please feel free to
            contact us.
          </Typography>

          <Typography variant="h6">Informació de contacte:</Typography>
          <Typography variant="body1" paragraph>
            Telèfon: + 34 973 641 681
          </Typography>

          <Typography variant="h6">Horari d'oficina:</Typography>
          <Typography variant="body1">
            Del 01/10 al 31/05 de dilluns a divendres de 9h a 14h
          </Typography>
          <Typography variant="body1" paragraph>
            Del 01/06 al 30/09 de dilluns a divendres de 9h a 17h
          </Typography>

          <Typography variant="body1" paragraph>
            O bé envia'ns un correu electrònic a{" "}
            <a href="mailto:info@carrosdefoc.com" style={styles.emailLink}>
              info@carrosdefoc.com
            </a>
          </Typography>
        </Paper>
      </Container>
      <Box sx={displayOnMobile}>
        <MobileBottomNav />
      </Box>
    </>
  );
};

export default ContactPage;
