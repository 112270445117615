import React from "react";
import { StyledActionLoadingButton } from "Styles/mui/StyledActionLoadingButton";

const BookItActionButton = ({ isLoadingSearch, handleSearch, buttonText }) => {
  return (
    <StyledActionLoadingButton
      fullWidth
      loading={isLoadingSearch}
      onClick={handleSearch}
      variant="text"
    >
      {buttonText}
    </StyledActionLoadingButton>
  );
};

export default React.memo(BookItActionButton);
