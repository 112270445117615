const checkoutFormModel = (t) => {
  return {
    formId: "checkoutForm",
    formField: {
      nif: {
        name: "nif",
        label: t("checkoutForm.formField.nif.label"),
        requiredErrorMsg: t("checkoutForm.formField.nif.requiredErrorMsg"),
      },
      firstName: {
        name: "firstName",
        label: t("checkoutForm.formField.firstName.label"),
        requiredErrorMsg: t(
          "checkoutForm.formField.firstName.requiredErrorMsg"
        ),
      },
      lastName: {
        name: "lastName",
        label: t("checkoutForm.formField.lastName.label"),
        requiredErrorMsg: t("checkoutForm.formField.lastName.requiredErrorMsg"),
      },
      email: {
        name: "email",
        label: t("checkoutForm.formField.email.label"),
        requiredErrorMsg: t("checkoutForm.formField.email.requiredErrorMsg"),
      },
      phone: {
        name: "phone",
        label: t("checkoutForm.formField.phone.label"),
        requiredErrorMsg: t("checkoutForm.formField.phone.requiredErrorMsg"),
      },
      address1: {
        name: "address1",
        label: t("checkoutForm.formField.address1.label"),
        requiredErrorMsg: t("checkoutForm.formField.address1.requiredErrorMsg"),
      },
      address2: {
        name: "address2",
        label: t("checkoutForm.formField.address2.label"),
      },
      city: {
        name: "city",
        label: t("checkoutForm.formField.city.label"),
        requiredErrorMsg: t("checkoutForm.formField.city.requiredErrorMsg"),
      },
      state: {
        name: "state",
        label: t("checkoutForm.formField.state.label"),
      },
      zipcode: {
        name: "zipcode",
        label: t("checkoutForm.formField.zipcode.label"),
        requiredErrorMsg: t("checkoutForm.formField.zipcode.requiredErrorMsg"),
        invalidErrorMsg: t("checkoutForm.formField.zipcode.invalidErrorMsg"),
      },
      country: {
        name: "country",
        label: t("checkoutForm.formField.country.label"),
        requiredErrorMsg: t("checkoutForm.formField.country.requiredErrorMsg"),
      },
      sortida: {
        name: "sortida",
        label: t("checkoutForm.formField.sortida.label"),
      },
      comments: {
        name: "comments",
        label: t("checkoutForm.formField.comments.label"),
      },
      acceptDataAcceptance: {
        name: "acceptDataAcceptance",
        label: t("checkoutForm.formField.acceptDataAcceptance.label"),
        requiredErrorMsg: t(
          "checkoutForm.formField.acceptDataAcceptance.requiredErrorMsg"
        ),
      },
    },
  };
};

export default checkoutFormModel;
