import { Typography } from "@mui/material";
import React from "react";

const HelpTags = ({ tags }) => (
  <Typography
    variant="body2"
    fontWeight="600"
    color="primary.dark"
    sx={{ mb: "8px" }}
  >
    {tags}
  </Typography>
);

export default HelpTags;
