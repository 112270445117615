import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Button, FormControl, InputLabel, NativeSelect } from "@mui/material";
import {
  MainTitleTable,
  TableCell,
  TableCellHeader,
  TableCellHeaderSmall,
  TableCellItemSmall,
  TableContainer,
  TableRow,
} from "../Table/styles";
import { getAgencyCodeId } from "../../store/modules/config/selectors";
import { useStayContext } from "../../context/StayContext";
import { useSearchContext } from "../../context/SearchContext";
import Box from "@mui/material/Box";
import SectionHeading from "../../pages/Help/dumbs/HelpSectionHeading";
import BubblesLoading from "../Shared/BubblesLoading";

const _ = require("lodash");

const AddButton = styled(Button)(({ theme }) => ({
  color: theme.palette.btn_add.main,
  borderColor: theme.palette.btn_add.main,
  borderRadius: (theme) => theme.global.btnBorderRadius,
  ":hover": {
    borderColor: theme.palette.btn_add.hover,
  },
  "&.Mui-disabled": {
    color: "#c0c0c0",
  },
  "&.MuiButton-outlined.Mui-disabled": {
    background: "#e4e4e4",
    color: "#b7babf",
  },
  "&.MuiButton-contained.Mui-disabled": {
    background: "#eaeaea",
    color: "#c0c0c0",
  },
}));

function SectionProductPrices({ products, refuge, isLoadingProducts }) {
  const { t } = useTranslation(["place"]);
  const { initStayData, addProduct } = useStayContext();
  const { isLoadingSearch, searchFilters } = useSearchContext();

  const agencyCodeId = useSelector(getAgencyCodeId);
  const [values, setValues] = useState({});

  useEffect(() => {
    initStayData(
      refuge,
      { start: searchFilters.startDate, end: searchFilters.endDate },
      searchFilters.peopleNumber
    );
  }, []);

  function addProductToStay(product, value) {
    product.agencyCodeId = agencyCodeId;
    addProduct(
      product,
      value,
      { start: searchFilters.startDate, end: searchFilters.endDate },
      searchFilters.peopleNumber
    );
  }

  function handleChange({ e, product }) {
    const { name } = e.target;
    const { value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    addProductToStay(product, parseInt(value, 10));
  }

  const options = [...Array((searchFilters.peopleNumber ?? []) + 1).keys()].map(
    (number) => (
      <option key={number} value={number}>
        {number}
      </option>
    ),
    this
  );

  return (
    <Box>
      <SectionHeading title={t("refuge.produts.heading")} />
      {isLoadingProducts ? (
        <BubblesLoading />
      ) : (
        <form>
          {products.map((service) => (
            <TableContainer key={`${refuge.id}{_}${service.title}`}>
              <tbody>
                <TableRow>
                  <TableCellHeader
                    key={`${refuge.id}{_}${service.title}{_title}`}
                  >
                    <MainTitleTable>
                      {_.capitalize(t("refuge.produts.tarifa"))}&nbsp;
                      {_.capitalize(service.title)}
                    </MainTitleTable>
                  </TableCellHeader>
                </TableRow>
                <TableRow>
                  <TableCellHeader
                    key={`${refuge.id}{_}${service.title}{_edats}`}
                  />
                  {service.columnsNames.map((col) => (
                    <TableCellHeaderSmall
                      key={`${refuge.id}{_}${service.title}{_edats_}${col}`}
                    >
                      {_.capitalize(col)}
                    </TableCellHeaderSmall>
                  ))}
                </TableRow>
                {service.rowsNames.map((row) => (
                  <TableRow key={`${refuge.id}{_}${service.title}{_}${row}`}>
                    <TableCellItemSmall
                      key={`${refuge.id}{_}${service.title}{_pensions}`}
                    >
                      {_.capitalize(row)}
                    </TableCellItemSmall>
                    {service.columnsNames.map((col, index) => (
                      <TableCell
                        key={`${refuge.id}{_}${service.title}{_pensions}${index}`}
                      >
                        {service.services[service.title][col].find(
                          (product) => product.name === row
                        ) && (
                          <FormControl variant="standard">
                            <InputLabel
                              variant="standard"
                              htmlFor={`select__${service.title}${col}${row}`}
                            >
                              {`${service.services[service.title][col]
                                .find((product) => product.name === row)
                                .price.toFixed(2)}€`}
                            </InputLabel>
                            <NativeSelect
                              inputProps={{
                                name: `select__${service.title}${col}${row}`,
                                id: `select__${service.title}${col}${row}`,
                              }}
                              value={
                                values[
                                  `select__${service.title}${col}${row}`
                                ] || 0
                              }
                              onChange={(e) => {
                                const selectedService = service.services[
                                  service.title
                                ][col].find((product) => product.name === row);
                                handleChange({
                                  e,
                                  product: selectedService,
                                });
                              }}
                            >
                              {options}
                            </NativeSelect>
                          </FormControl>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </tbody>
            </TableContainer>
          ))}
        </form>
      )}
    </Box>
  );
}

export default React.memo(SectionProductPrices);
