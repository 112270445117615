import React, {
} from 'react';
import { ButtonGroup } from '@mui/material';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import { makeStyles } from '@mui/styles';
import { useSearchContext } from '../../context/SearchContext';

const useStyles = makeStyles((theme) => ({
  number: {
    color: theme.palette.black,
    backgroundColor: theme.palette.primary.light,
  },
}));

function HostsGroupedBtn({
  hosts, onChange, onIncrement, onDecrement,
}) {
  const classes = useStyles();

  const {
    isNewSearch, setIsNewSearch,
    isLoadingSearch, setIsLoadingSearch,
    isLoadingProducts, setIsLoadingProducts,
    searchFilters,
    guests, setGuests,
  } = useSearchContext();

  return (
    <ButtonGroup size="small" aria-label="small outlined button group">
      <Button
        className={classes.number}
        variant="contained"
        disableElevation
        disabled={isLoadingSearch}
        onClick={onDecrement}
      >
        -
      </Button>
      <InputBase
        disabled={isLoadingSearch}
        onChange={onChange}
        variant="standard"
        id="standard-adornment-weight"
        type="number"
        min="1"
        max="100"
        value={hosts}
        aria-describedby="input-number-hosts"
        sx={{
          width: '35px',
          '& .MuiInputBase-input': {
            textAlign: 'center',
          },
        }}
      />
      <Button
        disabled={isLoadingSearch}
        className={classes.number}
        variant="contained"
        disableElevation
        onClick={onIncrement}
      >
        +
      </Button>
    </ButtonGroup>
  );
}

export default HostsGroupedBtn;
