import React, {useEffect} from "react";
import { useSelector } from "react-redux";
import HelpScreen from "../../../HelpScreen";
import HelpSection from "../../../dumbs/HelpSection";
import Box from "@mui/material/Box";
import HelpImage from "../../../dumbs/HelpImage";
import { Divider, Typography } from "@mui/material";
import { HelpOther, HelpOtherList, HelpSectionExtract } from "../../../styles";
import { useTranslation } from "react-i18next";
import { useUIContext } from "../../../../../context/UIContext";
import { usePage } from "../../../../../hooks/usePage";
import { useMountEffect } from "../../../../../hooks/useMountEffect";
import CookieCategories from "./CookieCategories";
import CookieListTable from "./CookieListTable";
import { selectCookiesByLocale } from "../../../../../store/modules/cookies/selectors";
import { getCurrentLanguage } from "../../../../../services/i18next/i18n";

const CookiesPage = ({ screen, showSearch }) => {
  usePage(screen);
  const { setShowSearchBox } = useUIContext();
  useMountEffect(() => setShowSearchBox(showSearch));

  const { t } = useTranslation("help");
  const cookies = useSelector((state) =>
    selectCookiesByLocale(state, getCurrentLanguage())
  );
  const necessaryCookies = cookies.filter(
    (cookie) => cookie.type === "estrictament_necessàries"
  );
  const functionalCookies = cookies.filter(
    (cookie) => cookie.type === "funcionals"
  );
  const performanceCookies = cookies.filter(
    (cookie) => cookie.type === "rendiment"
  );
  const selectionCookies = cookies.filter(
    (cookie) => cookie.type === "selecció"
  );

  const image = null;

  return (
    <HelpScreen title={t("cookies.title")} tags={t("cookies.tags")}>
      <Box>
        {image && <HelpImage image={image} />}
        <Box section-id="cancelation-type">
          <HelpSection
            title={t("cookies.sections.section1.title")}
            description={t("cookies.sections.section1.description")}
          >
            <CookieCategories />
          </HelpSection>
          <HelpSection
            id="section-heading-1-0"
            title={t("cookies.categories.category1.title")}
            description={t("cookies.categories.category1.description")}
          >
            <CookieListTable cookies={necessaryCookies} />
          </HelpSection>
          <HelpSection
            id="section-heading-2-0"
            title={t("cookies.categories.category2.title")}
            description={t("cookies.categories.category2.description")}
          >
            <CookieListTable cookies={functionalCookies} />
          </HelpSection>
          <HelpSection
            id="section-heading-3-0"
            title={t("cookies.categories.category3.title")}
            description={t("cookies.categories.category3.description")}
          >
            <CookieListTable cookies={performanceCookies} />
          </HelpSection>
          <HelpSection
            id="section-heading-4-0"
            title={t("cookies.categories.category4.title")}
            description={t("cookies.categories.category4.description")}
          >
            <CookieListTable cookies={selectionCookies} />
          </HelpSection>
        </Box>
      </Box>
      <Divider />
      <HelpOther>
        <Typography variant="subtitle1" sx={{ mb: "8px" }}>
          {t("other_help")}
        </Typography>
        <HelpOtherList>
          <li className="l1pvymst dir dir-ltr">
            <div
              aria-labelledby="article-card-1542"
              className="c1vrv309 dir dir-ltr"
            >
              <a
                href="/ajuda/recomanacions"
                className="t1imbvyv t14utcvq dir dir-ltr"
              >
                <Typography
                  variant="body2"
                  fontWeight="600"
                  color="primary.dark"
                  sx={{ mb: "8px" }}
                >
                  {t("recomanacions.group")}
                </Typography>

                <div>
                  <h3
                    id="article-card-1542"
                    tabIndex="-1"
                    className="hpipapi dir dir-ltr"
                    elementtiming="LCP-target"
                  >
                    {t("recomanacions.title")}
                  </h3>
                  <div className="s12spazh s13lem7z dir dir-ltr">
                    <HelpSectionExtract
                      dangerouslySetInnerHTML={{
                        __html:
                          t(
                            "recomanacions.sections.beforeLeaving.description"
                          ).substring(0, 1000) + "..." ?? "",
                      }}
                    />
                  </div>
                </div>
              </a>
            </div>
          </li>
          <li className="l1pvymst dir dir-ltr">
            <div
              aria-labelledby="article-card-248"
              className="c1vrv309 dir dir-ltr"
            >
              <a href="/ajuda/estada" className="t1imbvyv t14utcvq dir dir-ltr">
                <Typography
                  variant="body2"
                  fontWeight="600"
                  color="primary.dark"
                  sx={{ mb: "8px" }}
                >
                  {t("problems.group")}
                </Typography>

                <div>
                  <h3
                    id="article-card-248"
                    tabIndex="-1"
                    className="hpipapi dir dir-ltr"
                    elementtiming="LCP-target"
                  >
                    {t("problems.title")}
                  </h3>
                  <div className="s12spazh s13lem7z dir dir-ltr">
                    <HelpSectionExtract
                      dangerouslySetInnerHTML={{
                        __html:
                          t("problems.sections.section1.description").substring(
                            0,
                            1000
                          ) + "..." ?? "",
                      }}
                    />
                  </div>
                </div>
              </a>
            </div>
          </li>
        </HelpOtherList>
      </HelpOther>
    </HelpScreen>
  );
};

export default CookiesPage;
