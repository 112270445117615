import ReduxRefugeRepository from "../ReduxRefugeRepository";
import dispatchWithPromise from "../../../utils/dispatchWithPromise";

export class GetRefugeProductsUseCase {
  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  async execute(refugeId) {
    const { result, error } = await dispatchWithPromise(
      this.dispatch,
      ReduxRefugeRepository.getRefugeProducts(refugeId)
    );
    return { result, error };
  }
}
