import React from "react";
import {t} from "i18next";
import useCustomRoutes from "../../../../hooks/useCustomRoutes";
import HelpArticleCard from "../../dumbs/HelpArticleCard"; // Assuming i18n is configured

const ProblemsHelpArticle = ({variant}) => {
	const { getPath } = useCustomRoutes();

	return (
		<HelpArticleCard
			href={getPath("problems")}
			groupText={t("help:excursionista.problems.group")}
			title={t("help:excursionista.problems.title")}
			description={t(
				"help:excursionista.problems.sections.problemaAlRefugi.description"
			)}
			articleId="248"
			variant={variant}
		/>
	);
};

export default ProblemsHelpArticle;
