import { useState } from "react";
import { useTranslation } from "react-i18next";

export const BookingStatus = {
  DISABLED: "false",
  ALLOWED: "allowed",
  ENABLED: "true",
};

export function useBookingStatus() {
  const { t } = useTranslation(["place", "bookit"]);
  const [isBookingEnabled, setIsBookingEnabled] = useState({
    status: BookingStatus.DISABLED,
    msg: "",
  });

  function updateIsBookingEnabled(shoppingData) {
    const bookingEnabled =
      shoppingData.products &&
      shoppingData.guests ===
        shoppingData.products.reduce(
          (total, product) => total + product.quantity,
          0
        );
    setIsBookingEnabled({
      status: bookingEnabled ? BookingStatus.ENABLED : BookingStatus.ALLOWED,
      msg: bookingEnabled
        ? ""
        : `${t("bookit:bookit.btn_action.errors.num_products_pax", {
            count: shoppingData.guests,
          })}`,
    });
  }

  return {
    isBookingEnabled,
    setIsBookingEnabled,
    updateIsBookingEnabled,
  };
}
