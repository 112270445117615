/* eslint-disable max-len,no-use-before-define */
import allPass from "lodash/fp/allPass";
import moment from "moment";
import * as validators from "../Validators";
import { ResultsAvailabilityStatus } from "./Domain/ValueObject/AvailabilityStatus";
import * as utils from "./Utils";

const dateFormat = "DD-MM";
export const minimumNights = 1;
export const maximumNights = 3;

const requestAvailabilityDates = ({
  checkIn,
  checkOut,
  agencyOpenDate,
  agencyCloseDate,
  dateHourOffset,
}) => {
  const startAllowedDate = agencyOpenDate;
  const endAllowedDate = agencyCloseDate;

  const allowedDayFromNow = utils.allowedDayFromNow(dateHourOffset);
  const limitHour = utils.limitHour(dateHourOffset);
  const [nowStartDate, nowEndDate] = [
    allowedDayFromNow.clone(),
    moment().add(365, "day"),
  ];

  let startDate = checkIn.clone();
  let endDate = checkOut.clone();

  if (startDate.isBefore(allowedDayFromNow)) {
    startDate = checkIn.isBefore(limitHour)
      ? allowedDayFromNow
      : allowedDayFromNow.clone().add(1, "day");
    endDate = startDate.clone().add(1, "day");
  }

  let [momentStartDate, momentEndDate] = [null, null];
  if (isDateValid(startAllowedDate) && isDateValid(endAllowedDate)) {
    const agencyDateRange = {
      start: createDateObject(startAllowedDate).toDate(),
      end: createDateObject(endAllowedDate).toDate(),
    };
    [momentStartDate, momentEndDate] = [
      createDateObject(startAllowedDate),
      createDateObject(endAllowedDate),
    ];
    startDate = isDateInRange(startDate.toDate(), agencyDateRange)
      ? startDate
      : momentStartDate;
    endDate = checkOut.isAfter(startDate)
      ? checkOut
      : startDate.clone().add(1, "day");
  } else if (isDateValid(startAllowedDate) && !isDateValid(endAllowedDate)) {
    const agencyDateRange = {
      start: createDateObject(startAllowedDate).toDate(),
      end: createDateObject(endAllowedDate).toDate(),
    };
    [momentStartDate, momentEndDate] = [
      createDateObject(startAllowedDate),
      createDateObject(endAllowedDate),
    ];
    momentEndDate = momentStartDate.add(365, "day");

    startDate = isDateInRange(startDate.toDate(), agencyDateRange)
      ? startDate
      : momentStartDate;
    endDate = checkOut.isAfter(startDate)
      ? checkOut
      : startDate.clone().add(1, "day");
  }

  if (moment().isAfter(momentStartDate) || momentStartDate == null) {
    momentStartDate = nowStartDate;
    momentEndDate = nowEndDate;
  }

  return Object.freeze({
    startAllowedDate: momentStartDate,
    endAllowedDate: momentEndDate,
    startDate,
    endDate,
  });
};

const filterAvailableSites = (sites) => {
  return sites.filter((site) => {
    return Object.keys(ResultsAvailabilityStatus).includes(site.status);
  });
};

export const createDateObject = (dayAndMonthDate) =>
  moment(dayAndMonthDate, dateFormat);

export const isDateRangeValid = ({ start, end }) =>
  allPass([validators.isDateBeforeThan(end)])(start);

export const isDateValid = (date) =>
  allPass([validators.isString, validators.isLengthEqualThan(5)])(date);

export const isDateInRange = (date, range) =>
  allPass([
    validators.isDateAfterThan(range.start),
    validators.isDateBeforeThan(range.end),
  ])(date);

const nightRange = () => ({ minimumNights, maximumNights });

export const availabilityService = Object.freeze({
  requestAvailabilityDates,
  filterAvailableSites,
  isDateValid,
  isDateRangeValid,
  createDateObject,
  nightRange,
});
