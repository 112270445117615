import { handleActions } from "redux-actions";
import { handleCloseModal, isModalOpen, renderModalContent } from "./actions";

export const defaultState = {
  isModalOpen: false,
  handleCloseModal: () => null,
  renderModalContent: () => null,
};

export default handleActions(
  {
    [isModalOpen]: (state, { payload }) => ({
      ...state,
      isModalOpen: payload.isModalOpen,
    }),
    [handleCloseModal]: (state, { payload }) => ({
      ...state,
      handleCloseModal: payload.handleCloseModal,
    }),
    [renderModalContent]: (state, { payload }) => ({
      ...state,
      renderModalContent: payload.renderModalContent,
    }),
  },
  defaultState
);
