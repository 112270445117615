import React from "react";
import { Link } from "react-router-dom";
import useCustomRoutes from "../../hooks/useCustomRoutes";

const CustomLink = ({ route, children, ...props }) => {
	const { getPath } = useCustomRoutes();

	return (
		<Link to={`/${getPath(route)}`} {...props}>
			{children}
		</Link>
	);
};

export default CustomLink;
