import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Divider, Stack } from "@mui/material";
import { MdLocationOn } from "react-icons/md";
import { makeStyles } from "@mui/styles";
import { red } from "@mui/material/colors";
import _ from "lodash";
import Status from "../Refuge/Status";
import { ServicesItemWrapper } from "../Refuge/styles";
import { TransparentStyledCardActionArea } from "../../styles/mui/RefugeCard";
import { useTranslation } from "react-i18next";
import useCustomRoutes from "../../hooks/useCustomRoutes";

const useStyles = makeStyles({
  root: {
    maxWidth: 320,
  },
  leftAlignItem: {
    marginRight: "auto",
  },
  rightAlignItem: {
    marginLeft: "auto",
  },
  parentFlexLeft: {
    display: "flex",
    justifyContent: "flex-start",
  },
  parentFlexRight: {
    display: "flex",
    justifyContent: "flex-end",
  },
  parentFlexSplit: {
    display: "flex",
    justifyContent: "space-between",
  },
  avatar: {
    backgroundColor: red[500],
  },
});

function LocationItem({ location }) {
  return (
    <ServicesItemWrapper style={{ marginTop: "4px" }}>
      <MdLocationOn />
      {parseFloat(location.cords.latitude).toFixed(4)}
      º,
      {parseFloat(location.cords.longitude).toFixed(4)}º
    </ServicesItemWrapper>
  );
}

function RefugeMapCard({ refuge }) {
  const { getPath } = useCustomRoutes();

  const { t } = useTranslation("place");
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <TransparentStyledCardActionArea
        component={RouterLink}
        to={{
          pathname: getPath("refugeDetail").replace(":slug", refuge.site.slug),
          place: refuge,
        }}
      >
        <CardMedia
          component="img"
          height="140"
          image={refuge.site.images[0]}
          alt=""
        />
        <CardContent>
          <Typography gutterBottom variant="subtitle1" component="div">
            {refuge.site.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <>
              {refuge.site.category ? (
                <span>
                  {_.capitalize(t(`refuge.type.${refuge.site.category}`))}
                </span>
              ) : (
                <br />
              )}
            </>
          </Typography>
        </CardContent>
        <Divider />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 2, py: 1, bgcolor: "background.default" }}
        >
          <LocationItem
            location={{
              city: refuge.site.address.city,
              cords: refuge.site.location,
            }}
          />
          <Status statusCode={refuge.status} />
        </Stack>
      </TransparentStyledCardActionArea>
    </Card>
  );
}

export default React.memo(RefugeMapCard);
