import React, { useState } from "react";
import { Divider, HelpIndexSection } from "./styles";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import cancelImage from "Assets/images/help/cancel-word-on-keyboard.jpg";
import modificarImage from "Assets/images/help/David_Pereiras_Couple_doing_trekking_sitting_looking_mobile_and_map_4d8QKn_free.jpg";
import equipmentImage from "Assets/images/help/photo-by-alex-andrews.jpg";
import { COLORS } from "../../styles/colors";
import useCustomRoutes from "../../hooks/useCustomRoutes";
import {useUIContext} from "../../context/UIContext";
import {useMountEffect} from "../../hooks/useMountEffect";

const HelpIndex = ({ showSearch }) => {
  const { getPath } = useCustomRoutes();

  const { setShowSearchBox } = useUIContext();
  useMountEffect(() => setShowSearchBox(showSearch));

  const [activeTab, setActiveTab] = useState("reserva");
  const { t } = useTranslation();

  const tabs = [
    { key: "reserva", name: t("help:tabs.reserva") },
    { key: "excursionista", name: t("help:tabs.excursionista") },
    { key: "legals", name: t("help:tabs.legals") },
  ];

  const tabCardData = {
    reserva: [
      {
        title: t("help:reserva.cancelations.title"),
        image: cancelImage,
        link: "cancellations",
      },
      {
        title: t("help:reserva.modificacions.title"),
        image: modificarImage,
        link: "modifications",
      },
    ],
    excursionista: [
      {
        title: t("help:excursionista.recomanacions.title"),
        image: equipmentImage,
        link: "recommendations",
      },
    ],
    legals: [
      {
        title: t("help:excursionista.recomanacions.title"),
        image: "image8.jpg",
        link: "privacy_policy",
      },
      {
        title: t("help:excursionista.recomanacions.title"),
        image: "image9.jpg",
        link: "terms_conditions",
      },
      {
        title: t("help:legal.cookies.title"),
        image: "image10.jpg",
        link: "cookies",
      },
    ],
  };

  return (
    <Container disableGutters maxWidth="lg">
      <HelpIndexSection>
        <h1>{t("help:index.title")}</h1>
        <div className="content">
          <div id={"role_tabs"}>
            <div className={"tabs"}>
              <div className={"tabsWrapper"}>
                <div className={"tabsContent"}>
                  {tabs.map((tab) => (
                    <button
                      type="button"
                      key={tab.key}
                      className={`tab ${
                        activeTab === tab.key ? "active" : ""
                      }`}
                      onClick={() => setActiveTab(tab.key)}
                      role="tab"
                    >
                      {tab.name}
                    </button>
                  ))}
                </div>
                <Divider className={"tabsDivider"} />
              </div>
            </div>
            <div className={"tabContent"}>
              <GridContainer>
                {tabCardData[activeTab].map((card, index) => (
                  <CardLink
                    href={getPath(card.link)}
                    key={index}
                    aria-label={card.ariaLabel}
                    className="l1ovpqvx c1b36svn dir dir-ltr"
                  >
                    <ImageContainer
                      role="img"
                      aria-busy="false"
                      aria-label={card.ariaLabel}
                      style={{
                        backgroundImage: `url(${card.image})`,
                        backgroundSize: "cover",
                      }}
                    >
                      <img
                        src={card.image}
                        alt={card.ariaLabel}
                        className="i1ezuexe i1wndum8 i16t4q3z dir dir-ltr"
                        style={{ objectFit: "cover", display: "none" }}
                        aria-hidden="true"
                      />
                    </ImageContainer>

                    <CardTitle>{card.title}</CardTitle>
                  </CardLink>
                ))}
              </GridContainer>
            </div>
          </div>
        </div>
      </HelpIndexSection>
    </Container>
  );
};

export default HelpIndex;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
  padding: 20px 0;
`;

const CardLink = styled.a`
  text-decoration: none;
  color: inherit;
  display: block;
  overflow: hidden;
`;

const ImageContainer = styled.div`
  position: relative;
  height: 254px;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 8px;
`;

const CardTitle = styled.div`
  margin-top: 16px;
  font-size: 18px;
  color: ${COLORS.refusTextBlack};
  font-weight: 600;
  width: 100%;
`;
