import { createActions } from "redux-actions";

import {
  FETCH_REFUGE_BY_ID,
  FETCH_REFUGES,
  FETCH_SITE_PRODUCTS,
  SET_REFUGE,
  SET_REFUGES,
  SET_SERVICES,
} from "./actionTypes";

export const {
  fetchRefuges,
  fetchRefugeById,
  setRefuges,
  setRefuge,
  fetchSiteProducts,
  setServices,
} = createActions(
  FETCH_REFUGES,
  FETCH_REFUGE_BY_ID,
  SET_REFUGES,
  SET_REFUGE,
  FETCH_SITE_PRODUCTS,
  SET_SERVICES
);
