import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import HelpScreen from "../../HelpScreen";
import HelpSection from "../../dumbs/HelpSection";
import Box from "@mui/material/Box";
import HelpImage from "../../dumbs/HelpImage";
import equipmentImage from "Assets/images/help/photo-by-alex-andrews.jpg";
import {Divider, HelpOther, HelpOtherList,} from "../../styles";
import {Typography} from "@mui/material";
import {usePage} from "../../../../hooks/usePage";
import {useUIContext} from "../../../../context/UIContext";
import ProblemsHelpArticle from "../Excursionista/ProblemsHelpArticle";
import CancellationsHelpArticle from "../Reserva/CancellationsHelpArticle";
import {useMountEffect} from "../../../../hooks/useMountEffect";

const RecomanacionsPage = ({ screen, showSearch }) => {
  usePage(screen);
  const { setShowSearchBox } = useUIContext();
  useMountEffect(() => setShowSearchBox(showSearch));

  const { t } = useTranslation("help");
  const image = equipmentImage;

  return (
    <HelpScreen
      title={t("excursionista.recomanacions.title")}
      tags={t("excursionista.recomanacions.tags")}
    >
      <Box>
        {image && <HelpImage image={image} />}
        <Box section-id="cancelation-type">
          <HelpSection
            title={t(
              "excursionista.recomanacions.sections.beforeLeaving.title"
            )}
            description={t(
              "excursionista.recomanacions.sections.beforeLeaving.description"
            )}
          />
          <Divider />
          <HelpSection
            title={t("excursionista.recomanacions.sections.equipment.title")}
            description={t(
              "excursionista.recomanacions.sections.equipment.description"
            )}
          />
          <Divider />
          <HelpSection
            title={t("excursionista.recomanacions.sections.atTheRefuge.title")}
            description={t(
              "excursionista.recomanacions.sections.atTheRefuge.description"
            )}
          />
          <Divider />
          <HelpSection
            title={t(
              "excursionista.recomanacions.sections.leavingTheRefuge.title"
            )}
            description={t(
              "excursionista.recomanacions.sections.leavingTheRefuge.description"
            )}
          />
          <Divider />
        </Box>
      </Box>
      <HelpOther>
        <Typography variant="subtitle1" sx={{ mb: "8px" }}>
          Altres ajudes
        </Typography>
        <HelpOtherList>
          <ProblemsHelpArticle variant={'text'} />
          <CancellationsHelpArticle variant={'text'} />
        </HelpOtherList>
      </HelpOther>
    </HelpScreen>
  );
};

export default RecomanacionsPage;
