/* eslint-disable no-unused-vars */
import axios from 'axios';
import appConfig from '../config';

let api = null;

const getApiContext = () => appConfig.REFUS_API_CONTEXT;
const getApiKeyHeader = () => appConfig.REFUS_API_HEADER;
const getApiKey = () => appConfig.REFUS_API_KEY;

const getHeaders = async () => {
  const apiKeyHeader = getApiKeyHeader();
  const apiKey = getApiKey();
  const headers = {
    'Content-Type': 'application/json',
    accept: 'application/json',
  };
  headers[`${apiKeyHeader}`] = apiKey;

  return headers;
};

const getInitializedApi = () => {
  if (api) {
    return api;
  }
  api = axios.create({
    responseType: 'json',
  });
  return api;
};

export const get = async (url, params) => {
  const headers = await getHeaders();
  return getInitializedApi().get(process.env.REACT_APP_API_HOST.concat(url), { headers });
};

// eslint-disable-next-line default-param-last
export const post = async (url, data = {}, params) => {
  const headers = await getHeaders();
  return getInitializedApi().post(process.env.REACT_APP_API_HOST.concat(url), JSON.stringify(data), { headers });
};

export const del = async (url, params) => {
  const headers = await getHeaders();
  return getInitializedApi().delete(process.env.REACT_APP_API_HOST.concat(url), { headers });
};

// eslint-disable-next-line default-param-last
export const put = async (url, data = {}, params) => {
  const headers = await getHeaders();
  return getInitializedApi().put(process.env.REACT_APP_API_HOST.concat(url), JSON.stringify(data), { headers });
};
