import React from 'react';

const IconBookmark = ({ className }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 16 16"
		className={className}
		aria-hidden="true"
		role="presentation"
		focusable="false"
	>
		<path d="M12.5 0h-9A1.5 1.5 0 0 0 2 1.5v13.98l6-4.36 6 4.36V1.5A1.5 1.5 0 0 0 12.5 0z"></path>
	</svg>
);

export default IconBookmark;
