import { createActions } from "redux-actions";

import {
  SEARCH_DATES_ADD_ONE_DAY,
  SEARCH_RESET,
  SEARCH_UPDATE_FILTERS,
  SEARCH_UPDATE_IS_NEW,
  SEARCH_UPDATE_LOADING,
} from "./actionTypes";

export const {
  searchReset,
  searchUpdateFilters,
  searchUpdateIsNew,
  searchUpdateLoading,
  searchDatesAddOneDay,
} = createActions(
  SEARCH_RESET,
  SEARCH_UPDATE_FILTERS,
  SEARCH_UPDATE_IS_NEW,
  SEARCH_UPDATE_LOADING,
  SEARCH_DATES_ADD_ONE_DAY
);
