import { handleActions } from "redux-actions";
import moment from "moment";
import { setRefuge, setRefuges, setServices } from "./actions";

export const defaultState = {
  list: [],
  refuge: null,
  filters: {
    peopleNumber: 1,
    startDate: moment(),
    endDate: moment().add(1, "day"),
  },
  services: null,
};

const refugesReducer = handleActions(
  {
    [setRefuges]: (state, { payload }) => ({ ...state, list: payload.refuges }),
    [setRefuge]: (state, { payload }) => ({ ...state, refuge: payload.refuge }),
    [setServices]: (state, { payload }) => ({
      ...state,
      services: {
        ...payload.services,
      },
    }),
  },
  defaultState
);

export default refugesReducer;
