import React from "react";
import Typography from "@mui/material/Typography";
import {HelpSectionExtract} from "../styles";
import styled from "styled-components";
import {COLORS} from "../../../styles/colors";

const CardLink = styled.a`
  text-decoration: none;
  color: inherit;
  display: block;
  overflow: hidden;
`;

const ImageContainer = styled.div`
  position: relative;
  height: 254px;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 8px;
`;

const CardTitle = styled.div`
  margin-top: 16px;
  font-size: 18px;
  color: ${COLORS.refusTextBlack};
  font-weight: 600;
  width: 100%;
`;


const HelpArticleCard = ({
	                     href,
	                     groupText,
	                     title,
	                     description,
	                     articleId,
	                         variant,
                     }) => {
	return (
		<li>
			{variant === "text" ? (
			<div aria-labelledby={`article-card-${articleId}`}>
				<a href={href}>
					<Typography
						variant="body2"
						fontWeight="600"
						color="primary.dark"
						sx={{mb: "8px"}}
					>
						{groupText}
					</Typography>
					<div>
						<h3
							id={`article-card-${articleId}`}
							tabIndex={-1}
							className="hpipapi dir dir-ltr"
							elementtiming="LCP-target"
						>
							{title}
						</h3>
						<HelpSectionExtract
							dangerouslySetInnerHTML={{
								__html: `${description.substring(0, 1000)}...` ?? "",
							}}
						/>
					</div>
				</a>
			</div>
			) : (
				<CardLink href={href} aria-label={title}>
					{image && (
						<ImageContainer
							style={{ backgroundImage: `url(${image})` }}
							role="img"
							aria-busy="false"
							aria-label={title}
						>
							<img
								src={image}
								alt={title}
								style={{ objectFit: "cover", display: "none" }}
								aria-hidden="true"
							/>
						</ImageContainer>
					)}
					<CardTitle variant="h6">{title}</CardTitle>
				</CardLink>
			)}
		</li>
	);
};

export default HelpArticleCard;
