import React from "react";
import {t} from "i18next";
import useCustomRoutes from "../../../../hooks/useCustomRoutes";
import HelpArticleCard from "../../dumbs/HelpArticleCard"; // Assuming i18n is configured

const RecommendationsHelpArticle = ({variant}) => {
	const { getPath } = useCustomRoutes();

	return (
		<HelpArticleCard
			href={getPath("recommendations")}
			groupText={t("help:excursionista.recomanacions.group")}
			title={t("help:excursionista.recomanacions.title")}
			description={t(
				"help:excursionista.recomanacions.sections.beforeLeaving.description"
			)}
			articleId="1542"
			variant={variant}
		/>
	);
};

export default RecommendationsHelpArticle;
