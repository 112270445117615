import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import http from "i18next-http-backend";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { defaultLanguage, supportedLanguages } from "../../config/i18n";
import resources from "./resources";
if (!i18n.isInitialized) {
  const savedLanguage = localStorage.getItem('i18nextLng') || 'en';

  i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(http)
    .init({
      resources,
      supportedLngs: supportedLanguages.map((lang) => lang.code),
      detection: {
        order: ["localStorage", "navigator", "cookie", "querystring", "htmlTag"],
        caches: ["localStorage", "cookie"],
        checkWhitelist: true
      },
      nonExplicitSupportedLngs: true,
      lng: savedLanguage,
      debug: false,
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: false,
      },
    });
}
export default i18n;

export function languageCodeOnly(fullyQualifiedCode) {
  return fullyQualifiedCode.split("-")[0];
}

export function getCurrentLanguage() {
  return i18n.language;
}

export function changeLanguage(language) {
  return i18n.changeLanguage(language);
}

export function onLanguageChanged(callback) {
  return i18n.on("languageChanged", callback);
}
