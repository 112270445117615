import React from "react";
import Box from "@mui/material/Box";
import { colFlex, flexCenterAligned } from "../styles/themes/commonStyles";

const BaseMessageScreen = ({ children }) => {
  return (
    <Box
      sx={{
        p: { xxs: "32px", md: "60px", lg: "80px" },
        ...colFlex,
        ...flexCenterAligned,
      }}
    >
      {children}
    </Box>
  );
};

export default BaseMessageScreen;
