import React from 'react';
import Box from '@mui/material/Box';
import { colFlex, flexBetween, rowFlex } from '../../../styles/themes/commonStyles';
import { getAgencyInfo } from '../../../store/modules/config/selectors';
import { useSelector } from 'react-redux';
import { FaPhoneAlt } from 'react-icons/fa';
import Typography from '@mui/material/Typography';
function ContactInfo(props) {
  const info = useSelector(getAgencyInfo);
  return (
    <Box
      sx={{
        ...flexBetween,
        p: '10px',
      }}
    >
      <Box>
        <Typography variant="body1">Dilluns a divendres</Typography>
        <Typography variant="body1">Del 01/05 al 14/10 : de 9h a 17 h.</Typography>
        <Typography variant="body1">Del 15/10 al 30/04: de 9h a 14 h.</Typography>
      </Box>
      <Box
        sx={{...rowFlex}}
      >
        <Box sx={{marginRight: '6px'}}>
          <FaPhoneAlt size={14} /></Box>
          <a href="tel:${info.phone}}"> {info.phone}</a>
        </Box>
    </Box>
  );
}

export default ContactInfo;
