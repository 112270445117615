import styled from '@emotion/styled';
import {
  Button, CardActionArea, Divider, IconButton,
} from '@mui/material';
import { Box } from '@mui/system';
import { slideInBottom, slideInRight } from './animation';
import { COLORS } from '../colors';

export const TransparentStyledCardActionArea = styled(CardActionArea)(({ theme }) => `
    .MuiCardActionArea-focusHighlight {
        background: transparent;
    }
`);

export const RefugeCardBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  flexDirection: 'row',
  [theme.breakpoints.up('md')]: {
    position: 'relative',
    flexDirection: 'column',
  },
}));

export const RefugeCardDivider = styled(Divider)(({ theme }) => ({
  margin: '30px 0px',
}));

export const RefugeCardInfo = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: '10px',
}));

export const RefugeCardImage = styled('img')(({ src, theme }) => ({
  src: `url(${src})`,
  height: '100%',
  width: '100%',
  borderRadius: '12px',
  background: COLORS.white,
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: '0px',
  },
}));

export const ProductActionButton = styled(IconButton)(() => ({
  background: COLORS.white,
  margin: 4,
}));

export const ProductFavButton = styled(ProductActionButton)(({ isfav, theme }) => ({
  color: isfav ? COLORS.primary : COLORS.light,
  [theme.breakpoints.up('md')]: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
}));

export const ProductAddToCart = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'show',
})(({ show, theme }) => ({
  width: '120px',
  fontSize: '12px',
  [theme.breakpoints.up('md')]: {
    position: 'absolute',
    bottom: '2%',
    width: '300px',
    padding: '10px 5px',
    animation:
      show
      && `${slideInBottom} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`,
  },
  background: COLORS.secondary,
  opacity: 0.9,
}));

export const ProductMetaWrapper = styled(Box)(({ theme }) => ({
  padding: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const ProductActionsWrapper = styled(Box)(({ show, theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: show ? 'visible' : 'none',
    position: 'absolute',
    right: 0,
    top: '20%',
    animation: show && `${slideInRight} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`,
  },
}));
