import React from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";

function CustomerDetails(props) {
  const { formValues } = props;

  return (
    <Grid item xs={12} sm={6}>
      <Typography
        gutterBottom
      >{`${formValues.firstName} ${formValues.lastName}`}</Typography>
      <Typography gutterBottom>{`${formValues.nif}`}</Typography>
      <Typography gutterBottom>{`${formValues.address1}`}</Typography>
      {formValues.address2 && (
        <Typography gutterBottom>{`${formValues.address2}`}</Typography>
      )}
      <Typography gutterBottom>{`${formValues.city} ${
        formValues.state ? `${formValues.state}` : ""
      } ${formValues.zipcode} ${formValues.country}`}</Typography>
    </Grid>
  );
}

export default CustomerDetails;
