import React from "react";

import styled from "styled-components";
import { Button, Skeleton } from "@mui/material";
import { getStatusColor } from "../../lib/statusColor";
import { AvailabilityStatus } from "../../modules/Availability/Domain/ValueObject/AvailabilityStatus";
import { useTranslation } from "react-i18next";

function Status({ statusCode, isLoading, size }) {
  const { t } = useTranslation(["place"]);
  if (isLoading || !statusCode) {
    statusCode = "closed";
  }
  const statusObject = AvailabilityStatus(t)[statusCode];
  const statustheme = getStatusColor(statusCode);
  const StatusButton = styled(Button)(({ statustheme }) => ({
    padding: "4px 8px!important",
    cursor: "inherit!important",
    borderRadius: "8px!important",
    /* Color the border and text with theme.main */
    color: `${statustheme.main}!important`,
    border: `1px solid ${statustheme.main}!important`,
    backgroundColor: `${statustheme.bg}!important`,
    fontStyle: "normal!important",
    fontWeight: "500!important",
    fontSize: "12px!important",
    lineHeight: "17px!important",
    textTransform: "capitalize!important",
    minWidth: "80px!important",
    textAlign: "center",
  }));

  return (
    <div>
      {isLoading || !statusCode ? (
        <StatusButton size={size} variant="outlined" statustheme={statustheme}>
          <Skeleton
            animation="wave"
            sx={{
              width: "35px",
              height: "5%",
            }}
          />
        </StatusButton>
      ) : (
        <StatusButton variant="outlined" statustheme={statustheme}>
          {statusObject.label}
        </StatusButton>
      )}
    </div>
  );
}

export default Status;
