import { useRoutes } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ErrorPage from "Pages/ErrorPage";
import RefugeDetailScreen from "Pages/RefugeDetailScreen/RefugeDetailScreen";
import Root from "Pages/Root";
import CheckoutGuardRoute from "Pages/Checkout/CheckoutGuardRoute";
import NoOrderPage from "Pages/Checkout/NoOrderPage";
import ContactPage from "Pages/Contact/ContactPage";
import CancellationPage from "./pages/Help/Pages/Reserva/CancellationPage";
import CheckoutSuccess from "./pages/Checkout/CheckoutSuccess/CheckoutSuccess";
import CheckoutFailPayment from "./pages/Checkout/CheckoutFailPayment/CheckoutFailPayment";
import RecomanacionsPage from "./pages/Help/Pages/Recomanacions/RecomanacionsPage";
import BaseScreen from "./pages/BaseScreen";
import HomePage from "./pages/Home/HomePage";
import CookiesPage from "./pages/Help/Pages/Legals/Cookies/CookiesPage";
import HelpIndex from "./pages/Help/HelpIndex";
import ProblemesPage from "./pages/Help/Pages/Excursionista/ProblemesPage";

const createRoutes = (paths) => [
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      { path: "", element: <BaseScreen children={<HomePage screen="home" showSearch={true} />} /> },
      { path: paths.refugeDetail, element: <BaseScreen children={<RefugeDetailScreen screen="place" showSearch={true} />} /> },
      { path: paths.checkout, element: <BaseScreen children={<CheckoutGuardRoute showSearch={false} />} /> },
      { path: paths.checkoutOk, element: <BaseScreen children={<CheckoutSuccess showSearch={false} />} /> },
      { path: paths.checkoutFail, element: <BaseScreen children={<CheckoutFailPayment showSearch={false} />} /> },
      { path: paths.noOrder, element: <BaseScreen children={<NoOrderPage screen="noOrder" showSearch={false} />} /> },
      { path: paths.contact, element: <BaseScreen children={<ContactPage screen="contact" showSearch={false} />} /> },
      {
        path: paths.help,
        element: <BaseScreen children={<HelpIndex showSearch={false} />} />,
      },
      { path: paths.cancellations, element: <BaseScreen children={<CancellationPage screen="cancelation" showSearch={false} />} /> },
      { path: paths.recommendations, element: <BaseScreen children={<RecomanacionsPage screen="recommendations" showSearch={false} />} /> },
      { path: paths.problems, element: <BaseScreen children={<ProblemesPage screen="problems" showSearch={false} />} /> },
      { path: paths.cookies, element: <BaseScreen children={<CookiesPage showSearch={false} />} /> },
    ],
  },
];

const Router = () => {
  const { i18n } = useTranslation();
  const routesJson = require(`./routes/${i18n.language}.json`);
  const routes = createRoutes(routesJson);
  return useRoutes(routes);
};


export default Router;
