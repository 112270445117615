import cabinOpen from 'Assets/images/map/cabin-open.png';
import cabinClosed from 'Assets/images/map/cabin-closed.png';
import cabinFull from 'Assets/images/map/cabin-full.png';
import cabinMiddle from 'Assets/images/map/cabin-middle.png';
import cabinRestricted from 'Assets/images/map/cabin-restricted.png';

export const getStatusNewMarkerIcon = (status) => {
  switch (status) {
    case 'open':
      return cabinOpen;
    case 'closed':
      return cabinClosed;
    case 'full':
      return cabinFull;
    case 'event':
      return cabinOpen;
    case 'middle':
      return cabinMiddle;
    case 'restricted':
      return cabinRestricted;
    default:
      return cabinMiddle;
  }
};
