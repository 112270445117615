import { createActions } from "redux-actions";

import {
  APPEND_FORFAITS_TO_CART,
  APPEND_REFUGE_TO_CART,
  CONFIRM_ORDER,
  PAY_ORDER,
  PLACE_ORDER_ACTION,
  PLACE_ORDER_KO_ACTION,
  PLACE_ORDER_OK_ACTION,
  REMOVE_CART_ITEM,
  RESET_ORDER,
  SET_BOOKING_ID,
  SET_CUSTOMER_DATA,
  SET_ORDER_ID,
  SET_TPV_FORM,
  SET_TPV_ID,
  UPDATE_PICKED_FORFAITS,
} from "./actionTypes";

export const {
  appendRefugeToCart,
  removeCartItem,
  setCustomerData,
  placeOrderAction,
  placeOrderOkAction,
  placeOrderKoAction,
  confirmOrder,
  payOrder,
  setOrderId,
  setBookingId,
  resetOrder,
  updatePickedForfaits,
  appendForfaitsToCart,
  setTpvId,
  setTpvForm,
} = createActions(
  APPEND_REFUGE_TO_CART,
  REMOVE_CART_ITEM,
  SET_CUSTOMER_DATA,
  PLACE_ORDER_ACTION,
  PLACE_ORDER_OK_ACTION,
  PLACE_ORDER_KO_ACTION,
  CONFIRM_ORDER,
  PAY_ORDER,
  SET_ORDER_ID,
  SET_BOOKING_ID,
  RESET_ORDER,
  UPDATE_PICKED_FORFAITS,
  APPEND_FORFAITS_TO_CART,
  SET_TPV_ID,
  SET_TPV_FORM
);
