import * as Actions from "./actions";

export const initialState = {
  data: [],
  loading: false,
  error: null,
};

export const defaultState = {
  ...initialState,
};

export const cookiesAppReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.FETCH_COOKIES:
      return {
        ...state,
        loading: true,
      };
    case Actions.FETCH_COOKIES_OK:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.data,
      };
    case Actions.FETCH_COOKIES_KO:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
};
