import Container from "@mui/material/Container";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { flexBetween } from "../../styles/themes/commonStyles";
import { H1 } from "../../styles/Headings.styled";
import React from "react";
import HelpTags from "./dumbs/HelpTags";
import { useUIContext } from "../../context/UIContext";

const HelpScreen = ({ title, tags, children }) => {
  const { isMedium, isMobile } = useUIContext();

  return (
    <Container disableGutters maxWidth="lg">
      <Box
        sx={{
          ...flexBetween,
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "60%",
            ...(isMedium && {
              width: "100%",
              mb: 4,
              px: "48px",
            }),
            ...(isMobile && { px: "0" }),
          }}
        >
          <Grid
            sx={{
              pl: { xxs: "24px", sm: "40px" },
              pr: { xxs: "24px", sm: "24px" },
            }}
            item
            xxs={12}
            order={{ xxs: 2 }}
          >
            {title && (
              <Box
                sx={{
                  ...flexBetween,
                  pt: { xxs: "24px", sm: "28px" },
                  pb: { xxs: "16px", sm: "0" },
                }}
              >
                <Box>
                  <HelpTags tags={tags} />
                  <H1>{title}</H1>
                </Box>
              </Box>
            )}
            <Box>{children}</Box>
          </Grid>
        </Box>
        <Box
          sx={{
            width: "36.33%",
            ...(isMedium && {
              width: "0%",
            }),
          }}
        ></Box>
      </Box>
    </Container>
  );
};

export default HelpScreen;
