import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import {
  colFlex,
  flexBetween,
  fullWidthFlex,
  rowFlex,
} from "Styles/themes/commonStyles";
import { BigFooterLink, FooterLink, Heading } from "./styles";
import Copyright from "./Copyright";
import logo from "Assets/images/logobn-1.png";
import { useUIContext } from "../../../context/UIContext";
import { useTranslation } from "react-i18next";
import useCustomRoutes from "../../../hooks/useCustomRoutes";

const useStyles = makeStyles((theme) => ({
  linksColumn: {
    display: "flex",
    flexDirection: "column",
    "&:not(:last-child)": {
      paddingBottom: "24px",
      marginBottom: "24px",
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    [theme.breakpoints.up("md")]: {
      paddingBottom: "0!important",
      marginBottom: "0!important",
      borderBottom: "0!important",
    },
  },
  linksList: {
    display: "block",
    flexWrap: "wrap",
    listStyle: "none",
    margin: 0,
    padding: 0,
    [theme.breakpoints.between("sm", "md")]: {
      display: "flex",
      flexWrap: "wrap",
      marginLeft: "-12px",
      marginRight: "-12px",
    },
  },
  linksListItem: {
    display: "list-item",
    marginTop: "12px",
    [theme.breakpoints.between("sm", "md")]: {
      flexBasis: "33.3333%",
      paddingLeft: "12px",
      paddingRight: "12px",
    },
  },
}));

function Footer() {
  const { getPath } = useCustomRoutes();
  const theme = useTheme();
  const classes = useStyles();
  const { isHomePage } = useUIContext();
  const { t } = useTranslation("footer");

  return (
    <Box
      sx={{
        ...fullWidthFlex,
        backgroundColor: (theme) => theme.palette.primary.light,
      }}
    >
      <Container
        disableGutters
        id="footer"
        maxWidth={!isHomePage ? "lg" : false}
        sx={{
          px: { xxs: "24px", sm: "40px" },
          pt: { xxs: "24px", sm: "40px" },
        }}
      >
        <Box
          sx={{
            pb: { xxs: "24px", sm: "40px" },
            ...colFlex,
            [theme.breakpoints.up("md")]: {
              ...flexBetween,
              ...rowFlex,
              borderBottom: `1px solid ${theme.palette.primary.main}`,
            },
          }}
        >
          {/* Phone Support Section */}
          <Box
            sx={{
              [theme.breakpoints.up("md")]: {
                width: "30%",
              },
            }}
            className={classes.linksColumn}
          >
            <Box
              sx={{
                ...rowFlex,
                [theme.breakpoints.up("md")]: {
                  ...colFlex,
                },
              }}
            >
              <Box
                sx={{
                  mt: "16px",
                  mr: "60px",
                  [theme.breakpoints.up("md")]: {
                    m: 0,
                  },
                }}
              >
                <Box
                  sx={{
                    mb: "24px",
                  }}
                >
                  <BigFooterLink href="#">
                    {t("footer.phoneSupport")}
                  </BigFooterLink>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      [theme.breakpoints.up("md")]: {
                        mt: 2,
                      },
                    }}
                    variant="body2"
                  >
                    <div>
                      <strong>{t("footer.phoneSupportSchedule.first")}</strong>
                    </div>
                    <div>{t("footer.phoneSupportSchedule.second")}</div>
                    <div>{t("footer.phoneSupportSchedule.third")}</div>
                    <div>{t("footer.phoneSupportSchedule.fourth")}</div>
                  </Typography>
                </Box>
                <Box
                  component="img"
                  sx={{
                    my: "24px",
                    width: 150,
                    maxWidth: { s: 150, md: 150 },
                    [theme.breakpoints.up("md")]: {
                      width: "23%",
                    },
                  }}
                  alt="Refusonline.com - Central de reserves per refugis de muntanya"
                  src={logo}
                />
                <Typography variant="body2">
                  {t("footer.address")}
                  <br />
                  {t("footer.phoneNumber")}
                </Typography>
                <Typography variant="body2">{t("footer.email")}</Typography>
              </Box>
            </Box>
          </Box>
          {/* Assistance Section */}
          <Box
            sx={{
              [theme.breakpoints.up("md")]: {
                width: "23%",
              },
            }}
            className={classes.linksColumn}
          >
            <Heading>{t("footer.assistance")}</Heading>
            <ul className={classes.linksList}>
              <li className={classes.linksListItem}>
                <FooterLink href={getPath('cancellations')}>
                  {t("footer.cancellationOptions")}
                </FooterLink>
              </li>
              <li className={classes.linksListItem}>
                <FooterLink href={getPath('recommendations')}>
                  {t("footer.recommendationsAndPrecautions")}
                </FooterLink>
              </li>
            </ul>
          </Box>
          {/* Routes Section */}
          <Box
            sx={{
              [theme.breakpoints.up("md")]: {
                width: "23%",
              },
            }}
            className={classes.linksColumn}
          >
            <Heading>{t("footer.routes.title")}</Heading>
            <ul className={classes.linksList}>
              <li className={classes.linksListItem}>
                <FooterLink
                  href="https://carrosdefoc.refusonline.com/"
                  target="_blank"
                >
                  {t("footer.routes.carros")}
                </FooterLink>
              </li>
              <li className={classes.linksListItem}>
                <FooterLink
                  href="https://passaran.refusonline.com/"
                  target="_blank"
                >
                  {t("footer.routes.passAran")}
                </FooterLink>
              </li>
              <li className={classes.linksListItem}>
                <FooterLink href="https://caminreiau.cat/" target="_blank">
                  {t("footer.routes.caminReiau")}
                </FooterLink>
              </li>
            </ul>
          </Box>
          {/* Weather Section */}
          <Box
            sx={{
              [theme.breakpoints.up("md")]: {
                width: "23%",
              },
            }}
            className={classes.linksColumn}
          >
            <Heading>{t("footer.weather.title")}</Heading>
            <ul className={classes.linksList}>
              <li className={classes.linksListItem}>
                <FooterLink href="http://meteo.cat/" target="_blank">
                  {t("footer.weather.meteoCatForecast")}
                </FooterLink>
              </li>
              <li className={classes.linksListItem}>
                <FooterLink
                  href="http://france.lachainemeteo.com/meteo-france/previsions-meteo-france-0.php"
                  target="_blank"
                >
                  {t("footer.weather.laChaineMeteo")}
                </FooterLink>
              </li>
              <li className={classes.linksListItem}>
                <FooterLink
                  href="http://icgc.cat/Ciutada/Explora-Catalunya/Allaus/Butlleti-de-Perill-d-Allaus-BPA"
                  target="_blank"
                >
                  {t("footer.weather.avalancheRiskBulletin")}
                </FooterLink>
              </li>
              <li className={classes.linksListItem}>
                <FooterLink
                  href="http://lauegi.conselharan.org/"
                  target="_blank"
                >
                  {t("footer.weather.lauegiDAran")}
                </FooterLink>
              </li>
              <li className={classes.linksListItem}>
                <FooterLink
                  href="http://france.lachainemeteo.com/meteo-france/previsions-meteo-france-0.php"
                  target="_blank"
                >
                  {t("footer.weather.unisysForecast")}
                </FooterLink>
              </li>
              <li className={classes.linksListItem}>
                <FooterLink href="http://snow-forecast.com/" target="_blank">
                  {t("footer.weather.snowAtSkiResorts")}
                </FooterLink>
              </li>
            </ul>
          </Box>
          {/* Social Media Section */}
          <Box
            sx={{
              [theme.breakpoints.up("md")]: {
                width: "23%",
              },
            }}
            className={classes.linksColumn}
          >
            <Heading>Social Media</Heading>
            <Box
              sx={{
                ...rowFlex,
                [theme.breakpoints.up("md")]: {
                  ...colFlex,
                },
                mt: "16px",
              }}
            >
              <FooterLink
                href="https://facebook.com/carrosdefoc.oficial"
                target="_blank"
              >
                <i className="fab fa-facebook-f">
                  <span style={{ marginLeft: "10px" }}>
                    {t("footer.socialMedia.facebook")}
                  </span>
                </i>
              </FooterLink>
              <FooterLink
                href="https://instagram.com/carrosdefoc.oficial/"
                target="_blank"
              >
                <i className="fab fa-instagram">
                  <span style={{ marginLeft: "10px" }}>
                    {t("footer.socialMedia.instagram")}
                  </span>
                </i>
              </FooterLink>
            </Box>
          </Box>
        </Box>
        <Copyright />
      </Container>
    </Box>
  );
}

export default Footer;
