import React, {useEffect} from "react";
import HelpScreen from "../../HelpScreen";
import HelpSection from "../../dumbs/HelpSection";
import Box from "@mui/material/Box";
import HelpImage from "../../dumbs/HelpImage";
import cancelImage from "Assets/images/help/cancel-word-on-keyboard.jpg";
import {Typography} from "@mui/material";
import {Divider, HelpOther, HelpOtherList,} from "../../styles";
import {useTranslation} from "react-i18next";
import {useUIContext} from "../../../../context/UIContext";
import {usePage} from "../../../../hooks/usePage";
import {useMountEffect} from "../../../../hooks/useMountEffect";
import RecommendationsHelpArticle from "../Recomanacions/RecommendationsHelpArticle";
import ProblemsHelpArticle from "../Excursionista/ProblemsHelpArticle";

const CancellationPage = ({ screen, showSearch }) => {
  usePage(screen);
  const { setShowSearchBox } = useUIContext();
  useMountEffect(() => setShowSearchBox(showSearch));

  const { t } = useTranslation();
  const image = cancelImage;

  return (
    <HelpScreen
      title={t("help:reserva.cancelations.title")}
      tags={t("help:reserva.cancelations.tags")}
    >
      <Box>
        {image && <HelpImage image={image} />}
        <Box section-id="cancelation-type">
          <HelpSection
            title={t("help:reserva.cancelations.sections.section1.title")}
            description={t(
              "help:reserva.cancelations.sections.section1.description"
            )}
          />
          <br />
          <HelpSection
            title={t("help:reserva.cancelations.sections.section2.title")}
            description={t(
              "help:reserva.cancelations.sections.section2.description"
            )}
          />
        </Box>
      </Box>
      <Divider />
      <HelpOther>
        <Typography variant="subtitle1" sx={{ mb: "8px" }}>
          {t("help:other_help")}
        </Typography>
        <HelpOtherList>
          <RecommendationsHelpArticle variant={'text'} />
          <ProblemsHelpArticle variant={'text'} />
        </HelpOtherList>
      </HelpOther>
    </HelpScreen>
  );
};

export default CancellationPage;
