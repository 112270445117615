import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";
import HelpScreen from "../../HelpScreen";
import HelpSection from "../../dumbs/HelpSection";
import Box from "@mui/material/Box";
import HelpImage from "../../dumbs/HelpImage";
import equipmentImage from "Assets/images/help/photo-by-alex-andrews.jpg";
import {
  Divider,
  HelpOther,
  HelpOtherList,
  HelpSectionExtract,
} from "../../styles";
import { Typography } from "@mui/material";
import { usePage } from "../../../../hooks/usePage";
import { useUIContext } from "../../../../context/UIContext";
import { useMountEffect } from "../../../../hooks/useMountEffect";
import CancellationsHelpArticle from "../Reserva/CancellationsHelpArticle";
import RecommendationsHelpArticle from "../Recomanacions/RecommendationsHelpArticle";

const ProblemesPage = ({ screen, showSearch }) => {
  usePage(screen);
  const { setShowSearchBox } = useUIContext();
  useMountEffect(() => setShowSearchBox(showSearch));

  const { t } = useTranslation("help");
  const image = equipmentImage;

  return (
    <HelpScreen
      title={t("excursionista.problems.title")}
      tags={t("excursionista.problems.tags")}
    >
      <Box>
        {image && <HelpImage image={image} />}
        <Box section-id="cancelation-type">
          <HelpSection
            title={t(
              "excursionista.problems.sections.problemaAlRefugi.title"
            )}
            description={t(
              "excursionista.problems.sections.problemaAlRefugi.description"
            )}
          />
        </Box>
      </Box>
      <HelpOther>
        <Typography variant="subtitle1" sx={{ mb: "8px" }}>
          Altres ajudes
        </Typography>
        <HelpOtherList>
          <CancellationsHelpArticle variant={'text'} />
          <RecommendationsHelpArticle variant={'text'} />
        </HelpOtherList>
      </HelpOther>
    </HelpScreen>
  );
};

export default ProblemesPage;
