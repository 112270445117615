import { call, put, select, takeLatest } from "redux-saga/effects";
import * as api from "./api";
import { FETCH_COOKIES, fetchAppCookiesKo, fetchAppCookiesOk } from "./actions";
import { selectLocale } from "../language/selectors";

function* fetchAppCookies() {
  try {
    const locale = yield select(selectLocale);
    const response = yield call(api.getAppCookies, {
      params: {
        locale,
      },
    });
    yield put(fetchAppCookiesOk(response.data));
  } catch (err) {
    yield put(fetchAppCookiesKo(err));
  }
}

export function* watchFetchAppCookies() {
  yield takeLatest(FETCH_COOKIES, fetchAppCookies);
}
