import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit';
import { shallowEqual, useSelector } from 'react-redux';
import * as api from './api';
import { selectLocale } from '../language/selectors';

export const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const fetchConfigApp = createAsyncThunk('config/fetchConfigApp', async (locale) => {
  const response = await api.getConfigApp({
    params: {
      locale,
    },
  });

  return response.data;
});

const configSlice = createSlice({
  name: 'configApp',
  initialState,
  reducers: {

  },
  extraReducers(builder) {
    builder
      .addCase(fetchConfigApp.pending, (state, action) => {
        console.log(action);
        state.config.configApp.status = 'loading';
      })
      .addCase(fetchConfigApp.fulfilled, (state, action) => {
        console.log(action);
        state.config.configApp.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchConfigApp.rejected, (state, action) => {
        console.log(action);
        state.config.configApp.status = 'failed';
        state.config.configApp.error = action.error.message;
      });
  },
});

export default configSlice.reducer;

export const selectConfigApp = (state) => state.config.configApp;
