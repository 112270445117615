const checkoutInitialValues = (checkoutFormModel) => {
  const {
    formField: {
      nif,
      firstName,
      lastName,
      email,
      phone,
      address1,
      city,
      zipcode,
      country,
      sortida,
      comments,
      acceptDataAcceptance,
    },
  } = checkoutFormModel;

  return [
    {
      [nif.name]: "",
      [firstName.name]: "",
      [lastName.name]: "",
      [email.name]: "",
      [phone.name]: "",
      [address1.name]: "",
      [city.name]: "",
      [zipcode.name]: "",
      [country.name]: "",
      [sortida.name]: "",
      [comments.name]: "",
      [acceptDataAcceptance.name]: false,
    },
  ];
};

export default checkoutInitialValues;
