import IconBookmark from "../../../dumbs/IconBookmark";
import { HelpNavInPage } from "../../../styles";
import React from "react";

const CookieCategories = () => (
  <HelpNavInPage>
    <section>
      <h2 tabIndex="-1" elementtiming="LCP-target">
        En aquest article
      </h2>

      <ul className="cookie-categories">
        <li className="cookie-item">
          <a href="#section-heading-1-0" className="cookie-link">
            <span className="cookie-content">
              <IconBookmark className="cookie-icon" />
              <span className="cookie-label">
                Galetes estrictament necessàries
              </span>
            </span>
          </a>
        </li>
        <li className="cookie-item">
          <a href="#section-heading-2-0" className="cookie-link">
            <span className="cookie-content">
              <IconBookmark className="cookie-icon" />
              <span className="cookie-label">Galetes funcionals</span>
            </span>
          </a>
        </li>
        <li className="cookie-item">
          <a href="#section-heading-3-0" className="cookie-link">
            <span className="cookie-content">
              <IconBookmark className="cookie-icon" />
              <span className="cookie-label">Galetes de rendiment</span>
            </span>
          </a>
        </li>
        <li className="cookie-item">
          <a href="#section-heading-4-0" className="cookie-link">
            <span className="cookie-content">
              <IconBookmark className="cookie-icon" />
              <span className="cookie-label">Galetes de selecció</span>
            </span>
          </a>
        </li>
      </ul>
    </section>
  </HelpNavInPage>
);

export default CookieCategories;
