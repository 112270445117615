import styled from 'styled-components';
import { COLORS } from './colors';

export const H1 = styled.h1`
  font-size: 26px;
  font-weight: 600;
  line-height: 28px;
  margin: 0;
  padding: 0;
`;

export const H2 = styled.h2`
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  margin: 0;
  padding: 0;
`;

export const H3 = styled.h3`
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  margin: 0;
  padding: 0;
`;
