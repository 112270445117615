import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
// react icons
import { FaRegCopyright } from "react-icons/all";
import Typography from "@mui/material/Typography";
import { makeStyles, useTheme } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { FooterTermsLink } from "./styles";
import { flexCenter, fullWidthFlex } from "../../../styles/themes/commonStyles";
import { useUIContext } from "../../../context/UIContext";
import Language from "../../Language/Language";

const footerLinks = [
  { id: 1, text: "Politica de privacitat i Avís Legal", url: "#" },
  { id: 2, text: "Termes i Condicions d'Ús", url: "#" },
];
const useStyles = makeStyles((theme) => ({
  copyrightContainer: {
    display: "block",
    flexWrap: "wrap",
    listStyle: "none",
    margin: 0,
    padding: 0,
    [theme.breakpoints.up("md")]: {
      display: "flex!important",
      justifyContent: "space-between!important",
    },
  },
  contentWrapper: {
    display: "initial",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  languageWrapper: {
    justifyContent: "center",
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
}));

function Copyright() {
  const theme = useTheme();
  const classes = useStyles();

  const { t } = useTranslation("footer");
  const { isMobile } = useUIContext();

  return (
    <Box
      sx={{
        ...fullWidthFlex,
        py: "24px",
        borderTop: `1px solid ${theme.palette.primary.main}`,
      }}
    >
      <Container
        disableGutters
        maxWidth="xl"
        className={classes.copyrightContainer}
      >
        <Box className={classes.contentWrapper}>
          <Box
            sx={{
              [theme.breakpoints.down("md")]: {
                ...flexCenter,
              },
            }}
          >
            <Typography variant="body2">
              <FaRegCopyright /> {new Date().getFullYear()} Trekking Carros de
              Foc SL
            </Typography>
          </Box>
          <Box
            sx={{
              marginLeft: "5px",
              [theme.breakpoints.down("md")]: {
                ...flexCenter,
              },
            }}
          >
            <FooterTermsLink href="#">
              {"· "}
              {t("footer.copyright.politica")}
            </FooterTermsLink>
            <FooterTermsLink href="#">
              {" · "}
              {t("footer.copyright.terms")}
            </FooterTermsLink>
          </Box>
        </Box>
        <div className={classes.languageWrapper}>
          <Language />
        </div>
      </Container>
    </Box>
  );
}

export default React.memo(Copyright);
