import styled from 'styled-components';
import { COLORS } from '../../../styles/colors';

export const FooterComponent = styled.footer`
  background-color: ${COLORS.refusGreen};
  border-top: 1px solid #DDDDDD;
  position: relative !important;
  width: 100% !important;
  z-index: 2 !important;
  
  @media (min-width: 950px) {
    width: 547px !important;
  }
  @media (min-width: 1200px) {
    width: 620px !important;
  }
  @media (min-width: 1400px) {
    width: 780px !important;
  }
`;

export const Content = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
  max-width: 1440px;
    
  @media (min-width: 744px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (min-width: 550px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

export const BottomContent = styled.div`
  padding-bottom: 22px;
`;

export const NavigationMenu = styled.section`
  margin-bottom: 48px;
  color: ${COLORS.white};
  
  ul {
    list-style: none !important;
    margin: 0 !important;
    padding: 0 !important;

    @media (min-width: 744px) {
      display: flex !important;
      flex-wrap: wrap !important;
      margin-left: -12px !important;
      margin-right: -12px !important;
    }
  }
  
  i {
    float: left;
    margin: 2px 0 0 0;
    display: inline;
    width: auto;
    height: auto;
    line-height: normal;
    vertical-align: baseline;
    background-image: none;
    background-position: 0 0;
    background-repeat: repeat;
  }
  p {
   
  }
`;

export const NavigationMenuListItem = styled.li`
  display: list-item;
  text-align: -webkit-match-parent;
  list-style: none !important;
  margin-top: 12px !important;
  color: ${COLORS.white};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    
  @media (min-width: 744px) {
    flex-basis: 33.3333% !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
`;

export const ContactText = styled.p`
  margin: 0;
  font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
`;

export const CopyrightText = styled.p`
  color: ${COLORS.white};
  font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
  display: inline-block;
  @media (min-width: 1200px){
    margin-right: 19px;
  }
`;

export const Separator = styled.span`
  display: inline-block;
  text-align: center;
  width: 19px;
`;

export const Link = styled.a`
    text-decoration: none !important;
    font-weight: 400;
    color: ${COLORS.white};
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
`;

export const MyBox = styled.div`
  padding: 80px 60px;
  background: black;
  position: absolute;
  bottom: 0;
  width: 100%;
  
  @media (max-width: 1000px) {
    padding: 70px 30px;
  }
`;

export const MyContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;
    /* background: red; */
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 60px;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 
                         minmax(185px, 1fr));
  grid-gap: 20px;
   
  @media (max-width: 950px) {
    grid-template-columns: repeat(auto-fill, 
                           minmax(200px, 1fr));
  }
`;

export const BigFooterLink = styled.a`
  margin-bottom: 20px;
  margin-right: 16px;
  font-size: 18px;
  text-decoration: none;
   
  &:hover {
      text-decoration: underline;
      transition: 200ms ease-in;
  }
`;

export const FooterLink = styled.a`
  margin-bottom: 20px;
  margin-right: 16px;
  font-size: 14px;
  text-decoration: none;
   
  &:hover {
      text-decoration: underline;
      transition: 200ms ease-in;
  }
`;

export const FooterSocialLink = styled.a`
  margin-right: 16px;
  font-size: 14px;
  text-decoration: none;
   
  &:hover {
      text-decoration: underline;
      transition: 200ms ease-in;
  }
`;

export const FooterTermsLink = styled.a`
  font-size: 14px;
  text-decoration: none;   
  &:hover {
      text-decoration: underline;
      transition: 200ms ease-in;
  }
`;

export const Heading = styled.h3`
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  @media (min-width: 950px) {
    border-bottom: 2px solid #666600;
    padding-bottom: 10px;
    width: 60%;
  }
`;

export const MobileFooterContainer = styled.div`
  position:fixed;
	width: 100%;
  bottom:0;
  transition-timing-function: ease-in;
  transition:0.5s;
  background: white;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  z-index: 99999;
  border-top: 1px solid ${COLORS.border};
`;
