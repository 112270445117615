import React from "react";
import { Dialog, Divider, Drawer, IconButton, Slide } from "@mui/material";
import Box from "@mui/material/Box";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { makeStyles } from "@mui/styles";
import { useUIContext } from "../../context/UIContext";
import Cart from "../Cart/Cart";
import { colFlex, DrawerWidth } from "../../styles/themes/commonStyles";

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const useStyles = makeStyles((theme) => {
  const appbarHeight = 0;
  return {
    root: {
      top: `${appbarHeight}px !important`,
      paddingTop: "12px",
      "& .MuiPaper-root": {
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px",
      },
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(1, 0),
      justifyContent: "flex-start",
    },
    drawerTitleContainer: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    drawerTitleIcon: {
      marginRight: theme.spacing(1),
    },
  };
});

function CartDrawer() {
  const classes = useStyles();
  const { isMobile, cartOpen, setCartOpen } = useUIContext();

  const handleClose = () => {
    setCartOpen(false);
  };

  return (
    <>
      {isMobile ? (
        <Dialog
          role="dialog"
          fullScreen
          className={classes.root}
          open={cartOpen}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              ...colFlex,
              pb: "16px",
            }}
          >
            <Box className={classes.drawerHeader}>
              <IconButton onClick={handleClose} aria-label="close">
                <ChevronLeftIcon />
              </IconButton>
              <Box className={classes.drawerTitleContainer}>
                <ShoppingCartIcon className={classes.drawerTitleIcon} />
              </Box>
            </Box>
            <Divider />
            <Cart />
          </Box>
        </Dialog>
      ) : (
        <Drawer
          anchor="right"
          PaperProps={{
            sx: {
              width: DrawerWidth,
              borderRadius: 0,
            },
          }}
          open={cartOpen}
          onClose={() => setCartOpen(false)}
        >
          <Box
            sx={{
              ...colFlex,
            }}
          >
            <Box className={classes.drawerHeader}>
              <IconButton onClick={handleClose} aria-label="close">
                <ChevronLeftIcon />
              </IconButton>
              <Box className={classes.drawerTitleContainer}>
                <ShoppingCartIcon className={classes.drawerTitleIcon} />
              </Box>
            </Box>
            <Divider />
            <Cart />
          </Box>
        </Drawer>
      )}
    </>
  );
}

export default CartDrawer;
