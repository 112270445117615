const config = require('./default.js');
const envConfigFile = require('./env.json');
const appConfigFile = require('./app.js');

const ENVS = {
  dev: require('./dev.js'),
  staging: require('./staging.js'),
  production: require('./production.js'),
};

let envConfig = {};
try {
  envConfig = ENVS[envConfigFile.ENVIRONMENT];
  console.log(process.env);
  console.log(`Loading ${envConfigFile.ENVIRONMENT} environment`);
  console.log(`For ${appConfigFile.REFUS_APP_NAME} application`);
} catch (e) {
  console.log(`error while loading config: ${e}. Loading dev instead`);
  envConfig = ENVS.dev;
}

export default {
  ...config,
  ...appConfigFile,
  ...envConfig,
};
