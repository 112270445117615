import styled from "styled-components";
import { COLORS } from "../../styles/colors";
import { makeStyles } from "@mui/styles";

export const HelpImageSrc = styled.img`
  height: auto;
  width: 100%;
  position: static;
`;
export const HelpSectionExtract = styled.div`
  p {
    margin: 8px 0 0;
  }

  > ul {
    list-style-type: none; /* Remove bullets */
    padding: 0; /* Remove padding */
    margin: 0;
  }
`;
export const HelpSectionContent = styled.div`
  p {
    margin: 8px 0 16px;
  }
`;

export const HelpSectionTitle = styled.h2`
  margin-top: 16px !important;
  margin-bottom: 16px !important;
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${COLORS.refusLightGrey};
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const HelpOtherTitle = styled.h2`
  margin-top: 48px !important;
  margin-bottom: 16px !important;
`;

export const HelpOther = styled.section`
  margin-top: 48px !important;
  margin-bottom: 80px !important;
`;

export const HelpOtherList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;

  > li {
    margin: 0;
    padding: 24px 0;
    border-bottom: 1px solid ${COLORS.border};
  }

  h3 {
    font-size: 16px;
    margin-bottom: 4px;
  }

  .tag {
    font-size: 14px;
  }
`;

export const HelpNavInPage = styled.nav`
  border-bottom: 1px solid ${COLORS.border};
  margin: 40px 0;
  padding-bottom: 16px;

  h2 {
    font-size: 14px;
    color: ${COLORS.primary.dark};
  }

  ul.cookie-categories {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li.cookie-item {
    margin: 0 0 24px 0;
    padding: 0;
  }

  a.cookie-link {
    text-decoration: underline;
    color: inherit;
    font-weight: 500;
  }

  span.cookie-content {
    display: flex;
    align-items: center;
  }

  svg.cookie-icon {
    height: 12px;
    width: 12px;
    fill: currentColor;
    margin-right: 8px;
  }

  span.cookie-label {
    font-size: 14px;
  }
`;

export const HelpIndexSection = styled.section`
  max-width: 1200px;
  padding-left: 56px;
  padding-right: 56px;

  h1 {
    font-size: 32px;
    text-align: left;

    @media (min-width: 744px) {
      font-size: 48px;
      text-align: center;
    }
  }

  .tabsWrapper {
    padding-left: 12px;
    padding-right: 12px;
    margin-left: -12px;
    margin-right: -12px;

    .tabsDivider {
      margin: 5px 0 0;
    }
  }

  .tabsContent {
    display: flex;
    white-space: nowrap;
    overflow: visible;

    button {
      font-size: 14px;
      margin-right: 6px;
      border-radius: 8px;
      border: none;
      background: transparent;
      padding: 10px;
      margin-top: 6px;
      margin-bottom: 6px;
      transition: box-shadow 0.2s cubic-bezier(0.2, 0, 0, 1),
        transform 0.1s cubic-bezier(0.2, 0, 0, 1);
    }

    .tabs {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
      position: relative;
    }

    .tab {
      padding: 0.5rem 0;
      margin: 0 0.5rem;
      font-size: 1rem;
      border: none;
      background-color: transparent;
      cursor: pointer;
      color: ${COLORS.refusTextGrey};
      position: relative;
    }

    .tab.active {
      color: ${COLORS.refusTextBlack};
    }

    .tab.active::after {
      content: "";
      position: absolute;
      bottom: -6px;
      left: -5px;
      width: calc(100% + 10px);
      height: 2px;
      background-color: ${COLORS.refusTextBlack};
    }
  }

  .tabContent {
    padding-top: 36px;
    padding-bottom: 36px;
  }
`;

const useStyles = makeStyles((theme) => ({
  reset: {
    margin: 0,
    padding: 0,
  },
}));

export default useStyles;
