export const selectAllRefuges = (state) => state.refuges.list;

export const selectRefuge = (state) => state.refuges.refuge;
export const selectRefugeById = (id) => (state) => {
  if (state.refuges.refuge && state.refuges.refuge.site.id === id) {
    return state.refuges.refuge;
  }

  return null;
};

export const selectRefugeBySlug = (slug) => (state) => {
  if (state.refuges.refuge && state.refuges.refuge.site.slug === slug) {
    return state.refuges.refuge;
  }

  return null;
};

// eslint-disable-next-line max-len
export const findRefugeById = (id) => (state) => {
  if (state.refuges.list.length === 0) return null;
  return state.refuges.list.find((refuge) => refuge.site.id === id);
};

export const selectServices = (state) => {
  const { services } = state.refuges;
  if (!services) {
    return null;
  }
  const servicesKeys = [...Object.keys(services)];
  return servicesKeys.map((serviceKey) => {
    const columnsNames = Object.keys(services[serviceKey]);
    const pensionsArr = [];
    columnsNames.map((pensio) => {
      pensionsArr.push(
        services[serviceKey][pensio].map((product) => product.name)
      );
    });

    const rowsNames = [...new Set(pensionsArr.flat())];
    return {
      title: serviceKey,
      columnsNames,
      rowsNames,
      services,
    };
  });
};

export const selectRefugeDates = (state) => state.refuges.refuge.dates;
export const refuges = {
  selectRefuge: (state) => state.refuges.refuge,
  selectRefugeDates: (state) => state.refuges.refuge.dates,
};
