import React, { createContext, useContext, useState } from 'react';

const contextDefaultValues = {
  configs: {},
};

export const ConfigAppContext = createContext(contextDefaultValues);

export const useConfigApp = () => {
  const { configs, updateConfig } = useContext(ConfigAppContext);
  return { configs, updateConfig };
};

export function ConfigAppProvider({ children, configApp }) {
  const [configs, setConfigs] = useState(configApp);

  return (
    <ConfigAppContext.Provider value={{ configs, updateConfig: setConfigs }}>
      {children}
    </ConfigAppContext.Provider>
  );
}
