import React, { useState } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import { FaSearch } from "react-icons/fa";
import InputBase from "@mui/material/InputBase";
import { Divider } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Button from "@mui/material/Button";
import useModal from "../../hooks/useModal";
import { SearchContainer, SearchWrapper } from "./styles";
import { flexBetweenCenter } from "../../styles/themes/commonStyles";
import { fetchRefuges } from "../../store/modules/refuges/actions";
import { useSearchContext } from "../../context/SearchContext";
import { useUIContext } from "../../context/UIContext";
import { appendForfaitsToCart } from "../../store/modules/order/actions";
import { searchUpdateFilters } from "../../store/modules/search/actions";
import CalendarRangePicker from "./CalendarRangePicker";
import WithHomeLink from "../Navigation/WithHomeLink";
import { useTranslation } from "react-i18next";

const _ = require("lodash");

function LocationSearch() {
  const dispatch = useDispatch();
  const { t } = useTranslation("search");
  const theme = useTheme();
  const { isMobile, page } = useUIContext();
  const {
    setIsLoadingSearch,
    isLoadingSearch,
    setIsNewSearch,
    guests,
    setGuests,
    pickupSites,
    forfaits,
    agencyCodeId,
  } = useSearchContext();

  const [pickedForfaits, setPickedForfaits] = useState([]);
  const [pickedSite, setPickedSite] = useState(null);
  const [pickedDate, setPickedDate] = useState(null);
  const [isShowingForfaitsMenu, toggleForfaitsMenu] = useModal();
  const [isShowingRecollidaMenu, toggleRecollidaMenu] = useModal();

  const handleFetchRefuges = () => {
    async function fetchData() {
      await new Promise((resolve, reject) => {
        dispatch(fetchRefuges({ onComplete: resolve, onError: reject }));
      });
    }

    fetchData().then((r) => {
      setIsNewSearch(false);
    });
  };

  const onPeopleChanged = (event) => {
    const people = parseInt(event.target.value, 10) || 1;
    setGuests(people);
    dispatch(searchUpdateFilters({ filters: { peopleNumber: people } }));
  };

  const onSaveForfaitsHandler = (forfaits) => {
    setPickedForfaits(forfaits);
    toggleForfaitsMenu();
  };
  const onSavePickupHandler = (date, site) => {
    setPickedDate(date);
    setPickedSite(site);
    dispatch(
      appendForfaitsToCart({
        index: 0,
        refuge: {
          products: pickedForfaits.map((product) =>
            Object.assign(product, {
              agency_code_id: agencyCodeId,
              product_name: product.name,
              quantity: product.count,
              product_id: product.id,
              membership_id: product.membershipId,
              rang_edat_id: product.ageRangeId,
              price: product.price,
              deposit: product.deposit,
              membership_name: product.membershipName,
              rang_edat_name: product.ageRangeName,
            })
          ),
          site,
          from: date,
          to: date,
        },
      })
    );

    toggleRecollidaMenu();
  };

  const useStyles = makeStyles((theme) => ({
    hostInput: {
      width: "35px !important",
      "&.MuiInputBase-root .MuiInputBase-input": {
        padding: "0 !important",
      },
    },
  }));

  const classes = useStyles();

  return (
    <>
      {page && page === "place" ? (
        <WithHomeLink>
          <Button
            sx={{
              minWidth: page && page === "refuge" ? "185px" : "285px",
              height: "48px",
              justifyContent: "space-between",
              px: "8px",
              display: "inline-flex",
              alignItems: "center",
              border: "1px solid #ddd",
              borderRadius: 10,
              fontWeight: "bold",
              color: "#222222",
            }}
          >
            <Typography
              variant="small"
              sx={{
                px: "16px",
              }}
            >
              {t("searcher.placeholder")}
            </Typography>
            <IconButton
              sx={(theme) => ({
                "&:hover": { backgroundColor: theme.palette.secondary.main },
                width: "32px",
                height: "32px",
                pt: "12px",
                pb: "10px",
                pr: "10px",
                pl: "10px",
                borderRadius: "50%",
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.white,
              })}
            >
              <FaSearch />
            </IconButton>
          </Button>
        </WithHomeLink>
      ) : (
        <SearchContainer
          sx={{
            minWidth: page && page === "refuge" ? "185px" : "285px",
          }}
        >
          <SearchWrapper>
            {isLoadingSearch ? (
              <Skeleton
                animation="wave"
                sx={{
                  width: "92px",
                  height: "20px",
                }}
              />
            ) : (
              <Box
                sx={{
                  ...flexBetweenCenter,
                  [theme.breakpoints.up("xs")]: {
                    padding: "14px 32px",
                  },
                  padding: "14px 16px",
                }}
              >
                <InputBase
                  className={classes.hostInput}
                  onChange={onPeopleChanged}
                  variant="standard"
                  id="standard-adornment-weight"
                  type="number"
                  min="1"
                  max="100"
                  defaultValue={guests}
                  aria-describedby="input-number-hosts"
                  sx={{
                    width: "35px",
                  }}
                />
                <Typography>
                  hoste
                  {(guests > 1 || guests === 0) && "s"}
                </Typography>
              </Box>
            )}
            <Divider orientation="vertical" variant="middle" flexItem />
            {isLoadingSearch ? (
              <Skeleton
                animation="wave"
                sx={{
                  width: "130px",
                  height: "20px",
                }}
              />
            ) : (
              <Box
                className="searchDateRangePickerDesktop"
                sx={{
                  [theme.breakpoints.up("xs")]: {
                    padding: "0 24px",
                    mt: "10px",
                  },
                  padding: "0 10px",
                  marginTop: "10px",
                }}
              >
                <CalendarRangePicker
                  displayFormat="D MMM[.]"
                  orientation={isMobile ? "vertical" : "horizontal"}
                />
              </Box>
            )}
            <Box
              sx={{
                paddingRight: "9px",
              }}
            >
              <IconButton
                disabled={isLoadingSearch}
                onClick={handleFetchRefuges}
                sx={(theme) => ({
                  "&:hover": { backgroundColor: theme.palette.secondary.main },
                  width: "32px",
                  height: "32px",
                  marginLeft: "-6px",
                  paddingRight: "9px",
                  borderRadius: "50%",
                  backgroundColor: theme.palette.secondary.main,
                  color: theme.palette.white,
                })}
              >
                <FaSearch />
              </IconButton>
            </Box>
          </SearchWrapper>
        </SearchContainer>
      )}
    </>
  );
}

export default LocationSearch;
