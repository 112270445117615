export const selectCookies = (state) => state.cookies;
export const selectCookiesByLocale = (state, locale) => {
  const cookies = state.cookies.data;

  return cookies.map((cookie) => ({
    id: cookie.id,
    type: cookie.type,
    name: cookie[`name_${locale}`] || cookie.name_en, // Default to English if locale is missing
    description: cookie[`description_${locale}`] || cookie.description_en,
    expiration: cookie[`expiration_${locale}`] || cookie.expiration_en,
    management: cookie[`management_${locale}`] || cookie.management_en,
  }));
};
