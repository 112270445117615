import { handleActions } from "redux-actions";
import moment from "moment";
import {
  searchDatesAddOneDay,
  searchReset,
  searchUpdateFilters,
  searchUpdateIsNew,
  searchUpdateLoading,
} from "./actions";

export const initialState = {
  filters: {
    peopleNumber: 1,
    startDate: moment(),
    endDate: moment().add(1, "day"),
  },
  isNew: true,
  isLoading: false,
};

const refugesReducer = handleActions(
  {
    [searchReset]: (state, { payload }) => ({
      initialState,
    }),
    [searchUpdateFilters]: (state, { payload }) => ({
      ...state,
      filters: {
        ...state.filters,
        ...payload.filters,
      },
      isNew: false,
      isLoading: false,
    }),
    [searchUpdateLoading]: (state, { payload }) => ({
      ...state,
      isLoading: payload.isLoading,
    }),
    [searchUpdateIsNew]: (state, { payload }) => ({
      ...state,
      isNew: payload.isNew,
    }),
    [searchDatesAddOneDay]: (state) => {
      const { startDate, endDate } = state.filters;
      const newStartDate = endDate.clone();
      const newEndDate = newStartDate.clone().add(1, "day");

      return {
        ...state,
        filters: {
          ...state.filters,
          startDate: newStartDate,
          endDate: newEndDate,
        },
      };
    },
  },
  initialState
);

export default refugesReducer;
