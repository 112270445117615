import { useSelector } from "react-redux";
import { CheckoutProvider } from "../../context/CheckoutContext";
import CheckoutPage from "./Checkout";
import { Navigate } from "react-router-dom";
import { getCart } from "../../store/modules/order/selectors";
import useCustomRoutes from "../../hooks/useCustomRoutes";

const CheckoutGuardRoute = ({ screen, showSearch }) => {
  const { getPath } = useCustomRoutes();

  const cart = useSelector(getCart);

  if (cart.length > 0) {
    return (
      <CheckoutProvider>
        <CheckoutPage screen={screen} showSearch={showSearch} />
      </CheckoutProvider>
    );
  } else {
    return <Navigate to={`/${getPath('noOrder')}`} />;
  }
};

export default CheckoutGuardRoute;
