import React from "react";
import { Box } from "@mui/system";
import HelpSectionHeading from "./HelpSectionHeading";
import { HelpSectionContent } from "../styles";

const HelpSection = ({ id, title, description, children }) => (
  <Box id={id} sx={{ my: "16px" }}>
    <HelpSectionHeading title={title} />
    <HelpSectionContent
      dangerouslySetInnerHTML={{
        __html: description.replace(/\n/g, "<br />") ?? "",
      }}
    />
    {children}
  </Box>
);

export default HelpSection;
