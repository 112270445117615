import styled from 'styled-components';

export const EventsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #FFF;
  @media (max-width: 768px) {
    width: auto;
  }
`;

export const EventRow = styled.div`
  li{
    list-style-type: none;
    margin-bottom: 8px;
  }
`;

export const EventDateContainer = styled.div`
  display: inline-block !important;
  margin-right: 4px !important;
  vertical-align: middle !important;
`;

export const EventDate = styled.div`
  font-size: var(--font-text-micro-font-size, 12px) !important;
    line-height: var(--font-text-micro-line-height, 16px) !important;
    letter-spacing: var(--font-text-micro-letter-spacing, normal) !important;
    font-family: var(--font-text-micro-font-family, Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif) !important;
    text-transform: var(--font-text-micro-text-transform, undefined) !important;
    color: #484848 !important;
    padding-top: var(--spacing-status-label-vertical, 3px) !important;
    padding-bottom: var(--spacing-status-label-vertical, 3px) !important;
    font-weight: var(--font-bold-font-weight, 800) !important;
    border-top-right-radius: var(--border-status-label-border-radius, 2px) !important;
    border-bottom-right-radius: var(--border-status-label-border-radius, 2px) !important;
    padding-left: var(--spacing-status-label-horizontal, 6px) !important;
    padding-right: var(--spacing-status-label-horizontal, 6px) !important;
    border-top-left-radius: var(--border-status-label-border-radius, 2px) !important;
    border-bottom-left-radius: var(--border-status-label-border-radius, 2px) !important;
    background-color: #F2F2F2 !important;
`;

export const EventText = styled.div`
  font-size: var(--font-text-micro-font-size, 12px) !important;
`;
export const EventDescriptionTitle = styled.h6`
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  color: rgb(255, 90, 95);
`;
