import styled from '@emotion/styled';
import { alpha } from '@mui/material/styles';

export const CaptionContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  bottom: '0',
  left: '0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: 'auto',
  marginLeft: 'auto',
  padding: '5px 5px 15px 5px',
  whiteSpace: 'nowrap',
  backgroundColor: 'rgba(255,255,255,0.4)',
  borderTop: 'solid',
  borderColor: alpha(theme.palette.secondary.light, 0.6),
  borderRadius: '20px 20px 0 0',
  fontSize: theme.typography.fontSizes.xsm,
}));

export const CaptionLegend = styled.span`
 
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px 0 0;
`;
