import React from "react";
import { TextField } from "@mui/material";
import { useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import { selectAllRefuges } from "../../store/modules/refuges/selectors";
import { useMapContext } from "../../context/MapContext";
import { useTranslation } from "react-i18next";
import { sortMethods } from "../../utils/sortMethods";

function InputSearchBar() {
  const { t } = useTranslation("search");

  const { setSelectedItem } = useMapContext();

  const refuges = useSelector(selectAllRefuges);

  const defaultProps = {
    options: [].concat(refuges).sort(sortMethods["ascending"].method),
    getOptionLabel: (option) => option.site.name,
  };

  return (
    <div
      style={{
        display: "flex",
        alignSelf: "center",
        justifyContent: "center",
        flexDirection: "column",
        py: 20,
      }}
    >
      <Autocomplete
        {...defaultProps}
        id="include-input-in-list"
        onChange={(event, newValue) => {
          setSelectedItem(newValue);
        }}
        includeInputInList
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("results.input_label")}
            variant="standard"
          />
        )}
      />
    </div>
  );
}

export default React.memo(InputSearchBar);
