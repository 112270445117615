import { all } from 'redux-saga/effects';

import {
  watchFetchRefuges,
  watchFetchRefugeById,
  watchFetchSiteProducts,
} from './modules/refuges/sagas';

import {
  watchFetchConfigApp,
} from './modules/config/sagas';
import { watchConfirmOrder, watchPayOrder, watchPlaceOrder } from './modules/order/sagas';
import {watchFetchAppCookies} from "./modules/cookies/sagas";

export default function* rootSaga() {
  yield all([
    watchFetchConfigApp(),
    watchFetchRefuges(),
    watchFetchRefugeById(),
    watchFetchSiteProducts(),
    watchPlaceOrder(),
    watchConfirmOrder(),
    watchPayOrder(),
    watchFetchAppCookies(),
  ]);
}
