/* eslint-disable no-console */
import { call, put, select, takeLatest } from "redux-saga/effects";
import { CONFIRM_ORDER, PAY_ORDER, PLACE_ORDER_ACTION } from "./actionTypes";
import * as api from "./api";
import { placeOrderKoAction, placeOrderOkAction, setBookingId, setOrderId, setTpvForm, } from "./actions";
import { getBookingId, getCart, getOrderId, getPostReservation, getTpvId, } from "./selectors";
import { selectLocale } from "../language/selectors";
import { orderService } from "../../../modules/Order/OrderService";

function* placeOrder(action) {
  try {
    const order = yield select(getPostReservation);
    const locale = yield select(selectLocale);
    const response = yield call(api.placeOrder, {
      booking: {
        ...order,
        customer: {
          ...order.customer,
          locale,
        },
      },
    });

    if (response.data.result) {
      yield put(setBookingId({ bookingId: response.data.data.booking_id }));
      yield put(setOrderId({ orderId: response.data.data.order }));
      yield put(placeOrderOkAction());
      if (action.payload.onComplete) {
        action.payload.onComplete(response.data);
      }
    } else {
      yield put(placeOrderKoAction());
      if (action.payload.onError) {
        action.payload.onError(e);
      }
    }
  } catch (e) {
    if (action.payload.onError) {
      action.payload.onError(e);
    }
  }
}

export function* watchPlaceOrder() {
  yield takeLatest(PLACE_ORDER_ACTION, placeOrder);
}

function* confirmOrder(action) {
  const bookingId = yield select(getBookingId);
  const orderId = yield select(getOrderId);
  const body = {
    action: "confirm",
    order: orderId,
    booking_id: bookingId,
  };
  try {
    const response = yield call(api.confirmOrder, body);
    if (response.data.result) {
      action.payload.onComplete(response.data);
    } else {
      action.payload.onError(response.data);
    }
  } catch (e) {
    action.payload.onError(e);
  }
}

export function* watchConfirmOrder() {
  yield takeLatest(CONFIRM_ORDER, confirmOrder);
}

function* payOrder(action) {
  const operId = yield select(getTpvId);
  const bookingId = yield select(getBookingId);
  const orderId = yield select(getOrderId);
  const cart = yield select(getCart);
  const orderPrice = orderService.calculateOrderPrice(cart);
  const body = {
    order: orderId,
    amount: orderPrice,
    booking_id: bookingId,
    action: "deposit",
    operId: operId,
  };
  try {
    const response = yield call(api.payOrder, body);
    console.log(response.data);
    yield put(setTpvForm({ tpvForm: response.data.data }));
    if (response.data.result) {
      action.payload.onComplete(response.data);
    } else {
      action.payload.onError(response.data);
    }
  } catch (e) {
    action.payload.onError(e);
  }
}

export function* watchPayOrder() {
  yield takeLatest(PAY_ORDER, payOrder);
}
