import {
  fetchRefugeById,
  fetchSiteProducts,
} from "../../store/modules/refuges/actions";

class ReduxRefugeRepository {
  static findBySlug(slug) {
    return fetchRefugeById({
      slug,
      new: true,
    });
  }

  static getRefugeProducts(refugeId) {
    return fetchSiteProducts({ refugeId });
  }
}

export default ReduxRefugeRepository;
