import React from "react";
import moment from "moment";

import PropType from "prop-types";
import Box from "@mui/material/Box";
import { EventDate, EventDateContainer, EventRow, EventText } from "./styles";
import SectionHeading from "../../../pages/Help/dumbs/HelpSectionHeading";
import { useTranslation } from "react-i18next";

function Events({ events }) {
  const { t } = useTranslation(["place"]);
  return (
    <Box>
      <SectionHeading title={"Avisos / Esdeveniments"} />
      <ul style={{ padding: "0", listStyleType: "none" }}>
        {events.map((event) => (
          <EventRow key={Math.random()}>
            <li key={Math.random()}>
              <EventDateContainer>
                <EventDate>{moment(event.date).format("DD-MM-YYYY")}</EventDate>
              </EventDateContainer>
              <EventText>{event.text}</EventText>
            </li>
          </EventRow>
        ))}
      </ul>
    </Box>
  );
}

Events.defaultProps = {
  events: undefined,
};

Events.propTypes = {
  events: PropType.arrayOf(PropType.shape()),
};

export default Events;
