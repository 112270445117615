import styled from 'styled-components';

export const SearchContainer = styled.div`
  border: 1px solid #ddd;
  border-radius: 32px;
  background-color: #ffffff;
  box-shadow: 0 3px 12px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.08);
`;

export const SearchWrapper = styled.form`
  display: flex;
  align-items: center;
  flex: 1 1 0;
  height: 100%;
  min-width: 0;
  pointer-events: auto;
  --view-transition-name: search-input-content-big;
`;

export const SearchItemSeparator = styled.span`
  flex: 0 0 1px;
  height: 24px;
  width: 1px;
  background-color: #DDDDDD;
`;

export const ElementsContainer = styled.div`
  align-items: center;
  display: inline-flex;
  max-width: 100%;
  vertical-align: middle;
`;

export const MenuFilterItemWrapper = styled.div`
    align-items: center;
    display: flex;
    flex: 0 1 auto;
    height: 48px;
    min-width: 0;
    position: relative;
    z-index: 1;
`;

export const MenuFilterItem = styled.div`
  padding-right: 8px !important;
`;

export const FilterPersonContainer = styled.div`
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  outline: none !important;
  margin: 0px !important;
  font-size: 16px !important;
  line-height: 16px !important;
  font-weight: 400 !important;
  background: transparent;
  color: rgb(34, 34, 34) !important;
`;

export const InputLabel = styled.label`
  
`;

export const FilterDateContainer = styled.div`
  display: block;
  cursor: pointer !important;
  text-align: center !important;
  font-family: Circular,-apple-system,BlinkMacSystemFont,Roboto,"Helvetica Neue",sans-serif !important;
  position: relative !important;
  padding-bottom: 6px !important;
  padding-left: 16px !important;
  padding-right: 4px !important;
  outline: none !important;
  margin: 0px !important;
  background: transparent;
  
  > div {
    display: block;
    width: 100%
  }  
  input{
    font-size: 16px !important;
    line-height: 16px !important;
    font-weight: 400 !important;
    background: transparent;
    color: rgb(34, 34, 34) !important;
    padding: 0;
  }
  button {
    padding: 0 !important;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 4px;
    margin-right: 6px;
  }
  
  .DateInput {
    width: 60px !important;
    margin-left: 4px !important;
  }
  
  .DateRangePickerInput_arrow {
    margin-left: -10px !important;
    background: transparent;
    margin-right: 0 !important;
    z-index: 1 !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: rgb(34,34,34) !important;
  }
`;

export const InputIconLabel = styled.label`
  display: inline-block !important;
`;

export const ArrowIconLabel = styled.span`

`;

export const InputHostes = styled.input`
  background-color: #fff;
  width: 35px;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid transparent;
  border-left: 0;
  border-radius: 0;
  text-align: right;
  padding: 0;
  height: 18px;
  border: 0;
  font-size: 16px !important;
  line-height: 16px !important;
  background: transparent;
  color: rgb(34, 34, 34) !important;
`;

export const InputPickupButton = styled.button`
 cursor: pointer !important;
 text-align: center !important;
 font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important;
 position: relative !important;
 padding-top: 8px !important;
 padding-bottom: 8px !important;
 padding-left: 16px !important;
 padding-right: 16px !important;
 font-size: 12px !important;
 line-height: 16px !important;
 background-color: rgb(247, 247, 247) !important;
 color: rgb(34, 34, 34) !important;
 font-weight: 800 !important;
 border-width: 1px !important;
 border-style: solid !important;
 border-image: initial !important;
 outline: none !important;
 margin: 0px !important;
 border-radius: 30px !important;
 border-color: rgb(34, 34, 34) !important;
`;

export const InputPickupContent = styled.span`
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 16px !important;
`;

export const InputPickupTextWrapper = styled.div`
  display: flex !important;
`;

export const InputPickupText = styled.span`
  display: inline-block !important;
`;
