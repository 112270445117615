import { handleActions } from 'redux-actions';

import {
  setLanguage,
} from './actions';

export const defaultState = {
  locale: 'ca',
};

export default handleActions(
  {
    [setLanguage]: (state, action) => ({ ...state, locale: action.payload.language }),
  },
  defaultState,
);
