import React, { useState } from "react";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import { useSearchContext } from "../../context/SearchContext";
import { useUIContext } from "../../context/UIContext";
import { ArrowIconLabel } from "./styles";
import { availabilityService } from "../../modules/Availability/AvailabilityService";
import useModal from "../../hooks/useModal";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";

function CalendarRangePicker({ displayFormat, orientation, handleChange }) {
  const {
    isNewSearch,
    setIsNewSearch,
    checkIn,
    checkOut,
    guests,
    searchHandleDatesChange,
    isOutsideRange,
    searchFilters,
  } = useSearchContext();

  const { isMobile, isMedium } = useUIContext();
  const { minimumNights } = availabilityService.nightRange();
  const [focusedInput, setFocusedInput] = useState(null);
  const [isShowingForfaitsMenu, toggleForfaitsMenu] = useModal();
  const [isShowingRecollidaMenu, toggleRecollidaMenu] = useModal();

  const onFocusChange = (focused) => {
    if (isShowingRecollidaMenu) toggleRecollidaMenu();
    if (isShowingForfaitsMenu) toggleForfaitsMenu();

    setFocusedInput(focused);
  };

  const onDatesChange = ({ startDate, endDate }) => {
    searchHandleDatesChange(startDate, endDate, focusedInput);
    setIsNewSearch(true);
  };
  moment.locale("fr");
  return (
    <DateRangePicker
      focusedInput={focusedInput}
      startDate={!isNewSearch ? searchFilters.startDate : checkIn}
      startDateId={
        !isNewSearch
          ? searchFilters.startDate.toISOString()
          : checkIn.toISOString()
      }
      endDate={!isNewSearch ? searchFilters.endDate : checkOut}
      endDateId={
        !isNewSearch
          ? searchFilters.endDate.toISOString()
          : checkOut.toISOString()
      }
      onDatesChange={onDatesChange}
      onFocusChange={onFocusChange}
      displayFormat={displayFormat}
      isOutsideRange={(day) => isOutsideRange({ day, focused: focusedInput })}
      orientation={orientation}
      withFullScreenPortal={isMobile}
      verticalHeight={568}
      minimumNights={minimumNights}
      customArrowIcon={<ArrowIconLabel>-</ArrowIconLabel>}
      firstDayOfWeek={1}
    />
  );
}

export default CalendarRangePicker;
