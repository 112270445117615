import React from "react";
import {useNavigate} from "react-router-dom";
import {CookieConsentContent, CookieConsentLink, CookieConsentTitle,} from "./styles";
import {CookieConsent} from "react-cookie-consent";
import {useTranslation} from "react-i18next";
import useCustomRoutes from "../../hooks/useCustomRoutes";

function CookieConsentComponent() {
  const { getPath } = useCustomRoutes();

  let navigate = useNavigate();
  const { t } = useTranslation("cookies");
  return (
    <CookieConsentContent>
      <CookieConsent
        location="bottom"
        buttonText={t("banner.btn_accept")}
        declineButtonText={t("banner.btn_decline")}
        cookieName="cookieAccept-refusonline.com"
        expires={150}
        enableDeclineButton
        onDecline={() => {
          navigate(getPath('cookies'));
        }}
        style={{
          bottom: "8px",
          background: "#fff",
          border: "1px solid #222 !important",
          color: "#222",
        }}
        buttonStyle={{
          borderColor: "#222 !important",
          color: "#fff",
          fontSize: "1rem",
          backgroundColor: "#222",
          padding: "14px 24px",
          borderRadius: "8px",
        }}
        declineButtonStyle={{
          color: "#222",
          fontSize: "1rem",
          backgroundColor: "#fff",
          borderColor: "#222222!important",
          padding: "14px 24px",
          borderRadius: "8px",
        }}
      >
        <CookieConsentTitle>{t("banner.title")}</CookieConsentTitle>
        {t("banner.text1")}
        {t("banner.text2")}
        <CookieConsentLink href={getPath('cookies')}>
          {t("banner.politica_cookies")}
        </CookieConsentLink>
      </CookieConsent>
    </CookieConsentContent>
  );
}

export default CookieConsentComponent;
