import React from "react";
import {useTranslation} from "react-i18next";
import {Container, Form} from "react-bootstrap";
import {supportedLanguages} from "../../config/i18n";
import {useNavigate} from "react-router-dom";

function Language() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="section language">
      <Container>
        <Form.Select
          defaultValue={i18n.resolvedLanguage}
          onChange={(e) => {
            i18n.changeLanguage(e.target.value);
            localStorage.setItem("i18nextLng", e.target.value);
            navigate('/');
          }}
        >
          {supportedLanguages.map(({ code, name }) => {
            return (
              <option key={code} value={code}>
                {name}
              </option>
            );
          })}
        </Form.Select>
      </Container>
    </div>
  );
}

export default React.memo(Language);
