import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const CookieListTableWrapper = styled.div`
  margin: 20px 0;
  font-family: Arial, sans-serif;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 16px;
`;

const TableHeader = styled.th`
  background-color: #f2f2f2;
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
`;

const TableCell = styled.td`
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
`;

const TableCaption = styled.caption`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 18px;
`;

const CookieListTable = ({ cookies }) => {
  const { t } = useTranslation("cookies");

  return (
    <CookieListTableWrapper>
      <StyledTable>
        <thead>
          <tr>
            <TableHeader>{t("table.name")}</TableHeader>
            <TableHeader>{t("table.purpose")}</TableHeader>
            <TableHeader>{t("table.management")}</TableHeader>
            <TableHeader>{t("table.expiration")}</TableHeader>
          </tr>
        </thead>
        <tbody>
          {cookies &&
            cookies.map((cookie, index) => (
              <tr key={index}>
                <TableCell>{cookie.name}</TableCell>
                <TableCell>{cookie.description}</TableCell>
                <TableCell>{cookie.management}</TableCell>
                <TableCell>{cookie.expiration}</TableCell>
              </tr>
            ))}
        </tbody>
      </StyledTable>
    </CookieListTableWrapper>
  );
};

export default CookieListTable;
