import { applyMiddleware, compose } from 'redux';
import { configureStore } from '@reduxjs/toolkit';

import thunk from 'redux-thunk';

import createSagaMiddleware from 'redux-saga';

import appReducer from './rootReducers';

import rootSagas from './rootSagas';

// Check if gloval.__DEV__ exist in all the environments/devices;
/* eslint-disable */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-enable */
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, thunk];

const rootReducer = (state, action) => {
  let newState = { ...state };
  if (action.type === 'LOGOUT') {
    newState = undefined;
  }
  return appReducer(newState, action);
};

export default (initialState) => {
  const store = configureStore({
      reducer: rootReducer,
      initialState,
      middleware: [sagaMiddleware],
    });

  sagaMiddleware.run(rootSagas);

  return store;
};
