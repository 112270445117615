import moment from 'moment';
import { createDateRange } from '../../lib/createDateRange';

const _ = require('lodash');

export const PrepareStayFromCart = (item) => {
  const stayDates = createDateRange(new Date(item.from), new Date(item.to));
  let finalDates = stayDates;
  finalDates = (stayDates.length === 1) ? stayDates.push(moment()) : stayDates;
  return _.initial(stayDates).map((date) => {
    const stayFrom = moment(date).format('YYYY-MM-DD');
    return {
      site_id: item.site.id,
      from: stayFrom,
      to: moment(stayFrom).clone().add(1, 'day').format('YYYY-MM-DD'),
      guests:
        item.products.map((data) => ({
          rate: {
            product: data.product_catalog_id,
            price: data.price,
            deposit: data.deposit,
            description: `${data.quantity} Tarifa ${data.membership_name} ${data.rang_edat_name}`,
          },
          quantity: data.quantity,
        })),
    };
  });
};
