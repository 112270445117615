import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import i18n from './services/i18next/i18n';
import StateLoader from './state.loader';
import createStore from './store/createStore';

/*
Sentry.init({
  dsn: 'https://c5a85b705c2b4d8a973adf65cdfefc20@o4504502409297920.ingest.sentry.io/4504502410477568',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
}); */

const stateLoader = new StateLoader();
const store = createStore(stateLoader.loadState());

store.subscribe(() => {
  stateLoader.saveState(store.getState());
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Suspense fallback="...loading">
      <App />
    </Suspense>
  </Provider>,
);

serviceWorker.unregister();
