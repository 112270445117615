export const getAgencyDates = (state) => (
  { open: state.config.configApp.data.dateRangePicker.open_date, close: state.config.configApp.data.dateRangePicker.close_date }
);

export const getAgencyConditions = (state) => state.config.configApp.data.dateRangePicker.conditions;

export const getAgencyCodeId = (state) => (state.config.configApp.data.id);

export const getMinHoursBookingAllowed = (state) => (
  state.config.configApp.data.dateRangePicker.conditions.min_hours_booking_allowed_to_date
);

export const getPickupSites = (state) => {
  const { pickup_sites: sites } = state.config.configApp.data.extra_services;
  if (!sites) {
    return [];
  }

  return sites;
};

export const getForfaits = (state) => {
  const { forfaits } = state.config.configApp.data.extra_services;
  if (!forfaits) {
    return [];
  }

  return forfaits;
};

export const getAgencyInfo = (state) => (
  {
    slogan: state.config.configApp.data.slogan,
    name: state.config.configApp.data.display_name,
    email: state.config.configApp.data.contact_email,
    phone: state.config.configApp.data.phone,
    logo_url: state.config.configApp.data.logo_url,
  }
);
