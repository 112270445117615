import { createContext, useContext, useState } from "react";
import moment from "moment";
import { AvailabilityStatus } from "../modules/Availability/Domain/ValueObject/AvailabilityStatus";
import { useTranslation } from "react-i18next";
import { BookingStatus, useBookingStatus } from "./booking/useBookingStatus";

export const StayContext = createContext({});
export const useStayContext = () => useContext(StayContext);

export function StayProvider({ children }) {
  const { t } = useTranslation(["place", "bookit"]);

  const { isBookingEnabled, setIsBookingEnabled, updateIsBookingEnabled } =
    useBookingStatus();
  const [datesFeedbackText, setDatesFeedbackText] = useState("");

  const [isLoadingAddToCart, setIsLoadingAddToCart] = useState(false);
  const [shoppingData, setShoppingData] = useState({
    products: [],
    site: null,
    from: null,
    to: null,
    id: null,
    guests: null,
  });

  function initStayData(refuge, dates, guests) {
    setShoppingData({
      products: [],
      site: refuge.site,
      from: dates.start,
      to: dates.end,
      id: refuge.site.id + dates.start + dates.end,
      guests,
    });
  }

  function resetProducts() {
    setShoppingData({
      ...shoppingData,
      products: [],
    });
  }

  function addProduct(product, qty, dates, guests) {
    const existing = shoppingData.products.find(
      (guest) => guest.product_catalog_id === product.product_catalog_id
    );
    if (existing && qty === 0) {
      setShoppingData({
        ...shoppingData,
        products: [
          ...shoppingData.products.filter(
            (regim) => regim.product_catalog_id !== product.product_catalog_id
          ),
        ],
        from: dates.start,
        to: dates.end,
        guests,
      });
    } else {
      setShoppingData({
        ...shoppingData,
        products: [
          ...shoppingData.products.filter(
            (regim) =>
              regim.product_catalog_id !== product.product_catalog_id ||
              regim.membership_id !== product.membershipId ||
              regim.rang_edat_id !== product.ageRangeId
          ),
          {
            description: "",
            agency_code_id: product.agencyCodeId,
            product_catalog_id: product.product_catalog_id,
            product_id: product.product_id,
            membership_id: product.membershipId,
            rang_edat_id: product.ageRangeId,
            price: product.price,
            deposit: product.deposit,
            membership_name: product.membershipName,
            product_name: product.name,
            rang_edat_name: product.ageRangeName,
            quantity: qty,
          },
        ],
        from: dates.start,
        to: dates.end,
      });
      guests;
    }
  }

  function nights() {
    return moment(shoppingData.to)
      .startOf("day")
      .diff(moment(shoppingData.from).startOf("day"), "days");
  }

  const handleDatesFeedback = (refuge) => {
    if (refuge.status) {
      const statusObject = AvailabilityStatus(t)[refuge.status];
      setIsBookingEnabled({
        status:
          statusObject.label === "disponible"
            ? BookingStatus.ALLOWED
            : BookingStatus.DISABLED,
        msg: "",
      });
      setDatesFeedbackText(statusObject.msg);
    } else {
      setDatesFeedbackText("Hi ha problemes per calcular la disponibilitat.");
    }
  };

  const value = {
    isBookingEnabled,
    setIsBookingEnabled,
    updateIsBookingEnabled,
    isLoadingAddToCart,
    setIsLoadingAddToCart,
    shoppingData,
    setShoppingData,
    initStayData,
    resetProducts,
    addProduct,
    nights,
    handleDatesFeedback,
    datesFeedbackText,
  };

  return <StayContext.Provider value={value}>{children}</StayContext.Provider>;
}
