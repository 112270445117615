import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import { RefugeCardInfo } from "../../styles/mui/RefugeCard";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  imageLoader: {
    height: 190,
    borderRadius: "20px",
    position: "relative",
    zIndex: 1000,
  },
  titleLoader: {
    width: "60%",
    marginTop: "8px",
    height: "8%",
  },
  contentLoader: {
    width: "40%",
    marginTop: "8px",
    height: "5%",
  },
}));

export function SkeletonCardImage() {
  const { t } = useTranslation(["place"]);
  const classes = useStyles();
  return (
    <Box sx={{ width: 1, mb: "12px" }}>
      <Skeleton
        variant="rectangular"
        height={200}
        className={classes.imageLoader}
      />
    </Box>
  );
}

function SkeletonCard() {
  const classes = useStyles();

  return (
    <Box sx={{ width: 1, mb: "12px" }}>
      <SkeletonCardImage />
      <RefugeCardInfo>
        <Skeleton className={classes.titleLoader} />
        <Skeleton animation="wave" className={classes.contentLoader} />
        <Skeleton animation="wave" className={classes.contentLoader} />
      </RefugeCardInfo>
    </Box>
  );
}

export default React.memo(SkeletonCard);
