import Box from "@mui/material/Box";
import React from "react";
import { HelpImageSrc } from "../styles";

const HelpImage = ({ image }) => {
  return (
    <Box
      sx={{
        display: "flex",
        pt: { xxs: 0, sm: "24px" },
      }}
    >
      <HelpImageSrc alt={image} src={image} loading="lazy" />
    </Box>
  );
};

export default HelpImage;
