import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  listItem: {
    padding: '16px',
  },
  total: {
    fontWeight: '700',
  },
  title: {
    marginTop: theme.spacing(2),
  },
}));
