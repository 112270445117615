import React from "react";
import {t} from "i18next";
import useCustomRoutes from "../../../../hooks/useCustomRoutes";
import HelpArticleCard from "../../dumbs/HelpArticleCard"; // Assuming i18n is configured

const CancellationsHelpArticle = ({variant}) => {
	const { getPath } = useCustomRoutes();

	return (
		<HelpArticleCard
			href={getPath("cancellations")}
			groupText={t("help:reserva.cancelations.group")}
			title={t("help:reserva.cancelations.title")}
			description={t(
				"help:reserva.cancelations.sections.section1.description"
			)}
			articleId="1542"
			variant={variant}
		/>
	);
};

export default CancellationsHelpArticle;
