import styled from "styled-components";

export const HeaderWrapper = styled.div`
  position: -webkit-sticky !important;
  position: sticky !important;
  width: 100% !important;
  height: 64px !important;
  top: 0px !important;
  z-index: 10 !important;

  @media (min-width: 744px) {
    height: 81px !important;
  }
`;

export const header = styled.header`
  font-weight: 600;
  position: relative !important;
  z-index: 5 !important;
`;

export const HeaderContainer = styled.div`
  background: #ffffff !important;
  box-shadow: 0 1px 0 #ebebeb !important;
  -webkit-transition-duration: 200ms !important;
  transition-duration: 200ms !important;
  -webkit-transition-property: background, box-shadow !important;
  -moz-transition-property: background, box-shadow !important;
  transition-property: background, box-shadow !important;
  -webkit-transition-timing-function: ease-out !important;
  transition-timing-function: ease-out !important;
  width: 100% !important;
`;

export const Content = styled.div`
  display: table !important;
  width: 100% !important;
  border-spacing: 0px !important;
`;

export const LogoWrapper = styled.div`
  display: table-cell !important;
  vertical-align: middle !important;
`;

export const LogoContainer = styled.div`
  position: relative !important;
  z-index: 20 !important;
`;

export const LogoLink = styled.a`
  display: table-cell !important; ;
`;

export const LogoLinkContainer = styled.div`
  display: flex;
  height: 64px !important;
  position: relative !important;
  text-align: center !important;
  text-decoration: none !important;
  -webkit-transition: 0.25s color !important;
  -moz-transition: 0.25s color !important;
  transition: 0.25s color !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  vertical-align: middle !important;
  white-space: nowrap !important;

  @media (min-width: 744px) {
    height: 80px !important;
  }
`;

export const LogoImageWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const LogoImage = styled.img`
  width: 34px;
`;

export const LogoTextWrapper = styled.div`
  display: flex;
  align-items: center;
  text-overflow: ellipsis !important;
  font-weight: 800 !important;
  font-size: 17px !important;
  color: #484848 !important;

  @media (min-width: 744px) {
  }
`;

export const HeaderRightSection = styled.div`
  display: table-cell !important;
  vertical-align: middle !important;
  height: 64px !important;
  padding: 0px !important;
  margin: 0px !important;
  width: 100% !important;

  @media (min-width: 744px) {
    height: 80px !important;
  }
`;

export const CartContainer = styled.div`
  display: flex;
  align-items: center;
  float: right;
  padding-left: 24px !important;
  padding-right: 24px !important;
`;

export const ShoppingButton = styled.button`
  background-color: transparent;
  border: 0;
  cursor: pointer;
  font: inherit;
  line-height: normal;
  overflow: visible;
  font-weight: 700;
  color: rgb(255, 90, 95);
  display: flex;
  align-items: flex-end;
  position: relative;
  margin-right: 20px;
`;

export const ShoppingBadge = styled.div`
  border-radius: 50%;
  padding: 2px;
  height: 16px;
  width: 16px;
  font-size: 12px;
  position: absolute;
  top: 17px;
  right: -13px;
  background-color: rgb(255, 90, 95);
  color: white;
  font-weight: 900;
  line-height: 1.5;
`;

export const ShoppingButtonDisabled = styled(ShoppingButton)`
  color: lightgrey;
  cursor: unset;
`;

export const ShoppingBadgeDisabled = styled(ShoppingBadge)`
  background-color: lightgrey;
`;
