export const FETCH_COOKIES = "APP:COOKIES:FETCH";
export const FETCH_COOKIES_OK = "APP:COOKIES:FETCH_OK";
export const FETCH_COOKIES_KO = "APP:COOKIES:FETCH_KO";

export const fetchAppCookies = () => ({ type: FETCH_COOKIES });
export const fetchAppCookiesOk = (data) => ({
  type: FETCH_COOKIES_OK,
  payload: { data },
});
export const fetchAppCookiesKo = (error) => ({
  type: FETCH_COOKIES_KO,
  payload: { error },
});
