import { createActions } from 'redux-actions';

import {
  SET_LANGUAGE,
} from './actionTypes';

export const {
  setLanguage,
} = createActions(
  SET_LANGUAGE,
);
