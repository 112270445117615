import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';

export const StyledActionLoadingButton = styled(LoadingButton)(({ theme }) => ({
  padding: '10px 24px',
  color: theme.palette.white,
  backgroundColor: theme.palette.secondary.main,
  borderRadius: theme.global.btnBorderRadius,
  ':hover': {
    backgroundColor: theme.palette.secondary.dark,
  },
}));
