import { makeStyles } from '@mui/styles';
import { red } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: '0 0 0 0',
		marginBottom: '48px',
	},
	stepper: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(3),
		[theme.breakpoints.up('sm')]: {
			paddingTop: theme.spacing(4),
			paddingBottom: theme.spacing(4),
		},
	},
	activeStepLabel: {
		color: red,
	},
	buttons: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
	},
	header: {
		height: theme.global.headerHeight,
		padding: "12px",
	},
	left: {
		marginLeft: "12px",
	},
	center: {
		flex: 1,
	},
	checkoutHeaderTitle: {
		margin: 0,
	}
}));

export default useStyles;
