import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAllRefuges } from "../store/modules/refuges/selectors";
import {
  getAgencyCodeId,
  getAgencyConditions,
  getAgencyDates,
  getForfaits,
  getPickupSites,
} from "../store/modules/config/selectors";
import {
  availabilityService,
  maximumNights,
} from "../modules/Availability/AvailabilityService";
import { sortMethods } from "../utils/sortMethods";
import {
  selectFilters,
  selectIsLoadingSearch,
  selectIsNewSearch,
} from "../store/modules/search/selectors";
import {
  searchUpdateFilters,
  searchUpdateIsNew,
  searchUpdateLoading,
} from "../store/modules/search/actions";
import moment from "moment";
import { isInclusivelyAfterDay } from "react-dates";

export const SearchContext = createContext({});
export const useSearchContext = () => useContext(SearchContext);

export function SearchProvider({ children }) {
  const dispatch = useDispatch();
  const [sortState, setSortState] = useState("status");

  const allPlaces = useSelector(selectAllRefuges);
  const searchFilters = useSelector(selectFilters);
  const pickupSites = useSelector(getPickupSites);
  const forfaits = useSelector(getForfaits);
  const agencyCodeId = useSelector(getAgencyCodeId);
  const agencyConditions = useSelector(getAgencyConditions);
  const isLoadingSearch = useSelector(selectIsLoadingSearch);
  const isNewSearch = useSelector(selectIsNewSearch);

  // OPEN CONDITIONS
  const { min_hours_booking_allowed_to_date: dateHourOffset } =
    agencyConditions;
  const { open: agencyOpenDate, close: agencyCloseDate } =
    useSelector(getAgencyDates);

  // GUESTS
  const guests = useSelector(selectFilters).peopleNumber;
  const setGuests = (newGuests) => {
    dispatch(searchUpdateFilters({ filters: { peopleNumber: newGuests } }));
  };

  // DATES
  const { startDate: filterStartDate, endDate: filterEndDate } = searchFilters;
  const [checkIn, setCheckIn] = useState(filterStartDate);
  const [checkOut, setCheckOut] = useState(filterEndDate);

  const { startAllowedDate, endAllowedDate, startDate, endDate } =
    availabilityService.requestAvailabilityDates({
      checkIn,
      checkOut,
      agencyOpenDate,
      agencyCloseDate,
      dateHourOffset,
    });

  const isOutsideRange = ({ day, focused }) => {
    if (checkIn && focused === "endDate") {
      const maximumEndDate = checkIn.clone().add(maximumNights + 1, "days");
      return (
        !isInclusivelyAfterDay(day, startAllowedDate) ||
        isInclusivelyAfterDay(day, maximumEndDate)
      );
    }

    if (checkOut && focused === "startDate") {
      return (
        !isInclusivelyAfterDay(day, startAllowedDate) ||
        isInclusivelyAfterDay(day, endAllowedDate.clone().add(1, "day"))
      );
    }

    return (
      !isInclusivelyAfterDay(day, checkIn) ||
      moment(day).isAfter(endAllowedDate.add(1, "day"))
    );
  };

  useEffect(() => {
    const momentStartAllowedDate = moment(startAllowedDate);
    const nextDayStartAllowedDate = momentStartAllowedDate
      .clone()
      .add(1, "day");
    if (momentStartAllowedDate.isAfter(checkIn)) {
      setCheckIn(momentStartAllowedDate);
      setCheckOut(nextDayStartAllowedDate);
    }
    dispatch(
      searchUpdateFilters({
        filters: {
          startDate: momentStartAllowedDate,
          endDate: nextDayStartAllowedDate,
        },
      })
    );
  }, []);

  // AVAILABILITY
  const availablePlaces = availabilityService.filterAvailableSites(allPlaces);

  // CALENDAR DATES CHANGE
  const searchHandleDatesChange = (startDate, endDate, focusedInput) => {
    const time = moment("12:00", "HH:mm");
    if (startDate !== null) {
      startDate.set({
        hour: time.get("hour"),
        minute: time.get("minute"),
        second: time.get("second"),
      });
    }
    if (endDate !== null) {
      endDate.set({
        hour: time.get("hour"),
        minute: time.get("minute"),
        second: time.get("second"),
      });
    }

    const start = moment(startDate.toDate());
    const end = endDate ? moment(endDate.toDate()) : checkOut;

    setCheckIn(start);
    setCheckOut(end);

    if (focusedInput !== "startDate") {
      dispatch(
        searchUpdateFilters({
          filters: {
            startDate,
            endDate,
          },
        })
      );
    }
  };

  const value = {
    isLoadingSearch,
    isNewSearch,
    setIsNewSearch: (isNew) => dispatch(searchUpdateIsNew({ isNew })),
    setIsLoadingSearch: (isLoading) =>
      dispatch(searchUpdateLoading({ isLoading })),
    searchFilters,
    checkIn,
    setCheckIn,
    checkOut,
    setCheckOut,
    guests,
    setGuests,
    pickupSites,
    forfaits,
    agencyCodeId,
    startAllowedDate,
    endAllowedDate,
    startDate,
    endDate,
    places: [].concat(allPlaces).sort(sortMethods[sortState].method),
    availablePlacesCount: availablePlaces.length,
    searchHandleDatesChange,
    isOutsideRange,
  };

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
}
