import { createActions } from 'redux-actions';

import {
  SET_ERROR,
  CLEAR_ERROR,
  SET_MOBILE_BOTTOM_NAV_SHOW,
  MOBILE_BOTTOM_NAV_SHOW,
} from './actionTypes';

export const {
  setError,
  clearError,
  setMobileBottomNavShow,
  mobileBottomNavShow,
  } = createActions(
  SET_ERROR,
  CLEAR_ERROR,
  SET_MOBILE_BOTTOM_NAV_SHOW,
  MOBILE_BOTTOM_NAV_SHOW
);
