export const MAP_SETTINGS = {
  DEFAULT_MAP_OPTIONS: {
    scrollwheel: false,
    mapTypeControl: true,
    fullscreenControl: false,
    streetViewControl: false,
    zoomControlOptions: {
      position: 7,
      style: 'SMALL',
    },
  },
  DEFAULT_CENTER: { lat: 41.75, lng: 1.60422 },
  DEFAULT_ZOOM: 8,
  MARKER_SIZE: 35,
  PIXEL_OFFSET: {
    MARKER: {
      X: 0,
      Y: -35,
    },
  },
  DIRECTIONS_OPTIONS: { suppressMarkers: true, preserveViewport: true },
};
