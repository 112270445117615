/* eslint-disable no-console */
import {call, put, select, takeLatest} from "redux-saga/effects";
import {FETCH_REFUGE_BY_ID, FETCH_REFUGES, FETCH_SITE_PRODUCTS,} from "./actionTypes";
import * as api from "./api";
import {setRefuge, setRefuges, setServices} from "./actions";
import {selectLocale} from "../language/selectors";
import {findRefugeById, selectRefuge, selectRefugeBySlug} from "./selectors";
import {selectFilters} from "../search/selectors";
import {searchUpdateFilters, searchUpdateLoading} from "../search/actions";

function* fetchRefuges(action) {
  try {
    yield put(searchUpdateFilters({ isLoading: true }));
    yield put(setRefuges({ refuges: [] }));
    const filters = yield select(selectFilters);
    const locale = yield select(selectLocale);
    const response = yield call(api.fetchAvailability, {
      params: {
        locale,
      },
      peopleNumber: filters.peopleNumber,
      from: filters.startDate.format("YYYY-MM-DD"),
      to: filters.endDate.format("YYYY-MM-DD"),
    });
    yield put(
      setRefuges({
        refuges:
          !response.data || !Array.isArray(response.data)
            ? []
            : Object.freeze(response.data),
      })
    );
    const refuge = yield select(selectRefuge);
    if (refuge && refuge.site) {
      const newRefuge = yield select(findRefugeById(refuge.site.id));
      yield put(setRefuge({ refuge: newRefuge }));
    }
    yield put(searchUpdateLoading({ isLoading: false }));
    action.payload.onComplete(true);
  } catch (e) {
    console.error(e);
    yield put(searchUpdateLoading({ isLoading: false }));
    action.payload.onError(e);
  }
}

export function* watchFetchRefuges() {
  yield takeLatest(FETCH_REFUGES, fetchRefuges);
}

function* fetchRefugeById(action) {
  try {
    let refuge = null;

    if (!action.payload.new) {
      refuge = yield select(selectRefugeBySlug(action.payload.slug));
    }

    if (!refuge) {
      const filters = yield select(selectFilters);
      const locale = yield select(selectLocale);
      const response = yield call(api.fetchSiteAvailabilityBySlug, {
        params: {
          locale,
          slug: action.payload.slug,
        },
        from: filters.startDate.format("YYYY-MM-DD"),
        to: filters.endDate.format("YYYY-MM-DD"),
        peopleNumber: filters.peopleNumber,
      });
      yield put(setRefuge({ refuge: response.data[0] }));
      refuge = yield select(selectRefugeBySlug(action.payload.slug));
    } else {
      yield put(setRefuge({ refuge }));
    }
    action.onComplete({ error: null, result: refuge });
  } catch (err) {
    action.onError({ error: err.message, result: null });
  }
}

export function* watchFetchRefugeById() {
  yield takeLatest(FETCH_REFUGE_BY_ID, fetchRefugeById);
}

function* fetchSiteProducts(action) {
  try {
    yield put(setServices({ services: {} }));
    const filters = yield select(selectFilters);
    const locale = yield select(selectLocale);
    try {
      const response = yield call(api.fetchSiteProducts, {
        params: {
          locale,
        },
        refugeId: action.payload.refugeId,
        from: filters.startDate.format("YYYY-MM-DD"),
        to: filters.endDate.format("YYYY-MM-DD"),
      });
      yield put(setServices({ services: response.data }));
      action.onComplete({ error: null, result: response.data });
    } catch (err) {
      action.onError({ error: err.message, result: null });
    }
  } catch (err) {
    console.log(yield select(selectFilters));
    console.error({ err });
  }
}

export function* watchFetchSiteProducts() {
  yield takeLatest(FETCH_SITE_PRODUCTS, fetchSiteProducts);
}
