import React from "react";
import { Link as RouterLink } from "react-router-dom";

const WithHomeLink = ({ children, ...rest }) => {
  return (
    <RouterLink to="/" {...rest}>
      {children}
    </RouterLink>
  );
};

export default WithHomeLink;
