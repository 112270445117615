import React, { createRef, useEffect, useState } from "react";
import {
  GoogleMap,
  InfoWindowF,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import { getStatusNewMarkerIcon } from "../../lib/images";
import Caption from "./Caption";
import { useConfigApp } from "../../context/ConfigAppContext";
import { MAP_SETTINGS } from "./constants";
import BubblesLoading from "../Shared/BubblesLoading";
import Box from "@mui/material/Box";
import RefugeMapCard from "./RefugeMapCard";
import { useMapContext } from "../../context/MapContext";
import { useUIContext } from "../../context/UIContext";
import { useSearchContext } from "../../context/SearchContext";

const _ = require("lodash");

const {
  MARKER_SIZE,
  DEFAULT_ZOOM,
  DEFAULT_CENTER,
  DEFAULT_MAP_OPTIONS,
  // PIXEL_OFFSET,
  // DIRECTIONS_OPTIONS,
} = MAP_SETTINGS;

function Map() {
  const { configs } = useConfigApp();
  const { isMobile } = useUIContext();
  const { places } = useSearchContext();
  const { setSelectedItem, selectedItem, setHoverItem, hoverItem } =
    useMapContext();

  const [elRefs, setElRefs] = useState([]);
  useEffect(() => {
    setElRefs((refs) => {
      return Array(places?.length)
        .fill()
        .map((_, i) => refs[i] || createRef());
    });
  }, [places]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyBHOmYzODwXrhuNlSurr551W_nA6eBHdCw", // ,
    // ...otherOptions
  });

  const [center, setCenter] = useState({
    lat: Number(configs.data.map.center.lat),
    lng: Number(configs.data.map.center.lng),
  });

  const [map, setMap] = useState(null);

  const containerStyle = {
    width: "100%",
    height: "91.5vh",
    ...(isMobile && {
      height: "85vh",
    }),
  };

  const divStyle = {
    background: `white`,
    border: `1px solid #ccc`,
    padding: 15,
  };

  const onLoad = React.useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback((map) => {
    setMap(null);
  }, []);

  const handleOnMarkerClick = (merker, place) => {
    setSelectedItem(place);
    setHoverItem(null);
  };

  const handleMapOnClick = (e) => {
    setSelectedItem(null);
    setHoverItem(null);
  };

  const handleCenterChanged = () => {
    if (map) {
      const newCenter = map.getCenter();
      console.log(newCenter);
    }
  };
  const renderMap = () => (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={Number(configs.data.map.zoom)}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{ ...DEFAULT_MAP_OPTIONS }}
      onClick={handleMapOnClick}
    >
      <>
        {!selectedItem && hoverItem && (
          <InfoWindowF
            position={{
              lat:
                hoverItem.site.location.latitude === "0"
                  ? Number(configs.data.map.center.lat)
                  : Number(hoverItem.site.location.latitude),
              lng:
                hoverItem.site.location.longitude === "0"
                  ? Number(configs.data.map.center.lng)
                  : Number(hoverItem.site.location.longitude),
            }}
            options={{ pixelOffset: new window.google.maps.Size(0, -40) }}
          >
            <div style={divStyle}>{hoverItem.site.name}</div>
          </InfoWindowF>
        )}
        {selectedItem && (
          <InfoWindowF
            position={{
              lat: Number(selectedItem.site.location.latitude),
              lng: Number(selectedItem.site.location.longitude),
            }}
            onCloseClick={() => handleMapOnClick(null)}
            options={{ pixelOffset: { width: 10, height: 10 } }}
          >
            <RefugeMapCard refuge={selectedItem} />
          </InfoWindowF>
        )}
      </>
      {isLoaded && <Caption />}
      {places.map((item, i) => {
        const refuge = item;
        const icon =
          item === selectedItem
            ? getStatusNewMarkerIcon(refuge.status)
            : getStatusNewMarkerIcon(refuge.status);

        return (
          <div key={refuge.site.id}>
            <Marker
              position={{
                lat:
                  refuge.site.location.latitude === "0"
                    ? Number(configs.data.map.center.lat)
                    : Number(refuge.site.location.latitude),
                lng:
                  refuge.site.location.longitude === "0"
                    ? Number(configs.data.map.center.lng)
                    : Number(refuge.site.location.longitude),
              }}
              icon={icon}
              onMouseOver={() => {
                setHoverItem(refuge);
              }}
              onMouseOut={() => {
                setHoverItem(null);
              }}
              onClick={(e) => {
                handleOnMarkerClick(e, refuge);
              }}
            />
          </div>
        );
      })}
    </GoogleMap>
  );

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? (
    renderMap()
  ) : (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "10vh",
      }}
    >
      <BubblesLoading />
    </Box>
  );
}

export default React.memo(Map);
