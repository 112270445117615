import styled from 'styled-components';
import { COLORS } from '../../styles/colors';

export const Title = styled.h2`
  margin: 0px !important;
  word-wrap: break-word !important;
  font-family: Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif !important;
  font-size: 16px !important;
  font-weight: 800 !important;
  line-height: 1.375em !important;
  color: #484848 !important;
`;

export const LineSeparator = styled.div`
  border-top-width: 1px !important;
  border-top-style: solid !important;
  border-top-color: rgb(221, 221, 221);
`;

export const EmptySectionButton = styled.button`
  font-size: var(--font-button-small-font-size, 14px) !important;
  line-height: var(--font-button-small-line-height, 22px) !important;
  letter-spacing: var(--font-button-small-letter-spacing, normal) !important;
  font-family: var(--font-button-small-font-family, Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif) !important;
  text-transform: var(--font-button-small-text-transform, undefined) !important;
  padding-top: var(--spacing-button-small-vertical, 6px) !important;
  padding-bottom: var(--spacing-button-small-vertical, 6px) !important;
  font-weight: var(--font-book-font-weight, 600) !important;
  padding-left: var(--spacing-button-small-horizontal, 15px) !important;
  padding-right: var(--spacing-button-small-horizontal, 15px) !important;
  box-shadow: var(--shadow-button-level0-box-shadow, none) !important;
  color: #484848 !important;
  display: inline-block !important;
  position: relative !important;
  text-align: center !important;
  transition-property: background, border-color, color !important;
  transition-duration: 0.2s !important;
  transition-timing-function: ease-out !important;
  cursor: pointer !important;
  width: 100% !important;
  font-size: 14px !important;
  line-height: 22px !important;
  letter-spacing: normal !important;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  font-weight: 600 !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  min-width: 48.541px !important;
  box-shadow: none !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
  text-decoration: none !important;
  border-radius: 2px !important;
  border-width: 1px !important;
  border-style: solid !important;
  background: transparent !important;
  border-color: rgb(72, 72, 72) !important;
`;

export const EmptySectionButtonSpan = styled.span`
  font-size: var(--font-button-small-font-size, 14px) !important;
  line-height: var(--font-button-small-line-height, 22px) !important;
  letter-spacing: var(--font-button-small-letter-spacing, normal) !important;
  font-family: var(--font-button-small-font-family, Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif) !important;
  text-transform: var(--font-button-small-text-transform, undefined) !important;
  padding-top: var(--font-button-small-padding-top, undefined) !important;
  padding-bottom: var(--font-button-small-padding-bottom, undefined) !important;
`;

export const EmptySectionButtonTextWrapper = styled.div`
  overflow-wrap: break-word !important;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;TextItem
  line-height: 1.28571em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
`;

export const TextItem = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${COLORS.refusTextGrey};
  margin-right: 12px;
`;
