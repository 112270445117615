const statusRootKey = "refuge.status";

export const AvailabilityStatus = (t) =>
  Object.freeze({
    open: createStatus(t, "open"),
    middle: createStatus(t, "middle"),
    restricted: createStatus(t, "restricted"),
    full: createStatus(t, "full"),
    event: createStatus(t, "event"),
    closed: createStatus(t, "closed"),
  });

const createStatus = (t, status) => ({
  label: t(`${statusRootKey}.${status}.label`),
  msg: t(`${statusRootKey}.${status}.msg`),
});

export const ResultsAvailabilityStatus = Object.freeze({
  open: "open",
});
