import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import BaseMessageScreen from "../BaseMessageScreen";
import Button from "@mui/material/Button";
import trip_plan from "../../assets/images/stickers/trip_plan.png";
import { useTranslation } from "react-i18next";
import useStyles from "./checkout.styles";
import WithHomeLink from "../../components/Navigation/WithHomeLink";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    textAlign: "center",
    padding: "100px",
    backgroundColor: "#fff", // Background color
    borderRadius: "8px",
  },
  title: {
    fontSize: "24px",
    marginBottom: "16px",
  },
  message: {
    fontSize: "18px",
  },
};

const NoOrderPage = () => {
  const { t } = useTranslation("no_order");
  const classes = useStyles();
  return (
    <BaseMessageScreen>
      <Box>
        <img
          src={trip_plan}
          alt="Trip plan.Empty itinerary"
          style={{ width: "200px", height: "200px" }}
        />
      </Box>
      <Typography
        sx={{
          marginTop: "30px",
          marginBottom: "20px",
        }}
        variant="bodyBold"
        gutterBottom
      >
        {t("no_order.heading")}
      </Typography>
      <Box>
        <Typography variant="h5" color="primary.dark">
          {t("no_order.sub_heading")}
        </Typography>
      </Box>
      <Box
        sx={{
          marginTop: "40px",
        }}
      >
        <WithHomeLink>
          <Button variant="rounded" color="success">
            Home
          </Button>
        </WithHomeLink>
      </Box>
    </BaseMessageScreen>
  );
};

export default NoOrderPage;
