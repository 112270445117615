export const APPEND_REFUGE_TO_CART = "APPEND_REFUGE_TO_CART";
export const REMOVE_CART_ITEM = "REMOVE_CART_ITEM";
export const SET_CUSTOMER_DATA = "SET_CUSTOMER_DATA";
export const PLACE_ORDER_ACTION = "PLACE_ORDER_ACTION";
export const PLACE_ORDER_OK_ACTION = "PLACE_ORDER_OK_ACTION";
export const PLACE_ORDER_KO_ACTION = "PLACE_ORDER_KO_ACTION";
export const CONFIRM_ORDER = "CONFIRM_ORDER";
export const PAY_ORDER = "PAY_ORDER";
export const SET_ORDER_ID = "SET_ORDER_ID";
export const SET_BOOKING_ID = "SET_BOOKING_ID";
export const RESET_ORDER = "RESET_ORDER";
export const UPDATE_PICKED_FORFAITS = "UPDATE_PICKED_FORFAITS";
export const APPEND_FORFAITS_TO_CART = "APPEND_FORFAITS_TO_CART";
export const SET_TPV_ID = "SET_TPV_ID";
export const SET_TPV_FORM = "SET_TPV_FORM";
