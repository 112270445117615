import { handleActions } from 'redux-actions';
import {
  setConfigApp,
} from './actions';
import { initialState } from './configSlice';

export const defaultState = {
  configApp: initialState,
  theme: {
    pri50: '#e4f6eb',
    pri500: '#00b25c',
    pri700: '#009145',
    pri800: '#007f39',
    sec700: '#be2f79',
    sec900: '#802764',
  },
};

export default handleActions(
  {
    [setConfigApp]: (state, { payload }) => ({ ...state, configApp: payload }),
  },
  defaultState,
);
