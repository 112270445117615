import React, { useEffect, useRef } from "react";
import { BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";
import { CssBaseline } from "@mui/material";
import { HelmetProvider } from "react-helmet-async";
import { Helmet } from "react-helmet";
import { selectConfigApp } from "./store/modules/config/configSlice";
import { fetchConfigApp } from "./store/modules/config/actions";
import { ConfigAppProvider } from "./context/ConfigAppContext";
import AppThemeProvider from "./styles/themes/AppThemeProvider";
import { UIProvider } from "./context/UIContext";
import { SearchProvider } from "./context/SearchContext";
import ScrollToTop from "./components/ScrollToTop";
import Router from "./router";
import CookieConsentComponent from "./components/CookieConsentComponent/CookieConsentComponent";
import { fetchAppCookies } from "./store/modules/cookies/actions";
import { getCurrentLanguage } from "./services/i18next/i18n";

function App() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const firstRender = useRef(true);

  const [mode, setMode] = React.useState("light");
  const configApp = useSelector(selectConfigApp);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      dispatch(fetchConfigApp());
      dispatch(fetchAppCookies());
    }
  }, [configApp.status, dispatch]);

  useEffect(() => {
    document.dir = i18n.dir();
    document.documentElement.lang = getCurrentLanguage();
    document.title = i18n.t("document_title");
  }, [i18n, i18n.language]);

  if (configApp.status === "loading") {
    console.log("Loading...");
    <Spinner />;
  } else if (configApp.status === "failed") {
    console.log("failed");
  } else if (configApp.status === "succeeded") {
    return (
      <ConfigAppProvider configApp={configApp}>
        <HelmetProvider>
          <AppThemeProvider>
            <SearchProvider>
              <CssBaseline enableColorScheme />
              <UIProvider>
                <BrowserRouter>
                  <ScrollToTop>
                    <Helmet>
                      <script
                        src="https://sis-t.redsys.es:25443/sis/NC/sandbox/redsysV3.js"
                        async
                      />
                    </Helmet>
                    <CookieConsentComponent />
                    <Router fallbackElement={<p>Loading...</p>} />
                  </ScrollToTop>
                </BrowserRouter>
              </UIProvider>
            </SearchProvider>
          </AppThemeProvider>
        </HelmetProvider>
      </ConfigAppProvider>
    );
  }
}

export default React.memo(App);
