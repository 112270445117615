import { handleActions } from "redux-actions";

import {
  clearError,
  mobileBottomNavShow,
  setError,
  setMobileBottomNavShow,
} from "./actions";

export const defaultState = {
  errorMessage: "",
  mobileBottomNavShowing: false,
};

export default handleActions(
  {
    [setError]: (state, { payload }) => ({
      ...state,
      errorMessage: payload.errorMessage,
    }),
    [clearError]: (state, action) => defaultState,
    [setMobileBottomNavShow]: (state, { payload }) => ({
      ...state,
      mobileBottomNavShowing: payload,
    }),
    [mobileBottomNavShow]: (state, action) => defaultState,
  },
  defaultState
);
