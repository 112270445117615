import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet";
import { orderService } from "../../../modules/Order/OrderService";
import {
  getCart,
  getOrderId,
  getTpvForm,
} from "../../../store/modules/order/selectors";
import { formatPrice } from "../../../utils/formatPrice";
import { setTpvId } from "../../../store/modules/order/actions";
import { Box } from "@mui/system";
import { colFlex } from "../../../styles/themes/commonStyles";
import { useCheckoutContext } from "../../../context/CheckoutContext";
import Button from "@mui/material/Button";

function Payment({ handleStepCompletion }) {
  const dispatch = useDispatch();
  const cart = useSelector(getCart);
  const orderPrice = orderService.calculateOrderPrice(cart);
  const orderId = useSelector(getOrderId);
  const tpvForm = useSelector(getTpvForm);

  const { skipTPV, setOnSubmit } = useCheckoutContext();

  useEffect(() => {
    // Add an event listener to receive the idOper
    window.addEventListener("idOperReceived", (event) => {
      if (event.detail && event.detail.idOper) {
        dispatch(setTpvId({ operId: event.detail.idOper }));
        handleStepCompletion(null, null);
      }
    });

    window.addEventListener("validatePayment", (event) => {
      if (event.detail) {
        console.log(event.detail);
      }
    });

    // Other setup code inside useEffect
  }, []);

  const formRef = useRef();
  const handleSubmit = () => {
    if (formRef && formRef.current) {
      const formElement = formRef.current.querySelector("form");
      console.log(formElement);
      if (formElement) {
        console.log(formElement);
        formElement.submit();
      }
    }
  };

  setOnSubmit(handleSubmit);

  if (skipTPV) {
    // Display "Continue" button instead of TPV payment form
    return (
      <Box sx={colFlex}>
        <Button variant="containedAction" onClick={handleStepCompletion}>
          Continue
        </Button>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          paddingTop: "24px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Box sx={{ ...colFlex }}>
              <Typography variant="h6" gutterBottom>
                Bestreta a pagar ara: {formatPrice(orderPrice.deposit)}
              </Typography>
              <Typography variant="bodyBold" gutterBottom>
                La resta de l’import s’ha de pagar al refugi.
              </Typography>
              <Typography variant="bodyBold" gutterBottom>
                ( Consulta la taula de tarifes del refugi )
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {tpvForm ? (
          <>
            <div dangerouslySetInnerHTML={{ __html: tpvForm }} ref={formRef} />
            <Helmet>
              <script>{`
                function pay(){
                  console.log('pay from helmet');
                  document.forms[0].submit();
                }
              `}</script>
            </Helmet>
          </>
        ) : (
          <>
            <div id="card-form" style={{ height: "300px" }} />
            <form name="datos">
              <input type="hidden" id="token" />
              <input type="hidden" id="errorCode" />
            </form>
            <Helmet>
              <script>
                {`
              function merchantValidationEjemplo()
               {
                const validationEvent = new CustomEvent('validatePayment');
                window.dispatchEvent(validationEvent);
                return true;
              }

              window.addEventListener("message", function receiveMessage(event) 
              {
                storeIdOper(event, "token", "errorCode", merchantValidationEjemplo);  
                const { idOper } = event.data;
                const idOperEvent = new CustomEvent('idOperReceived', { detail: { idOper } });
                window.dispatchEvent(idOperEvent);
              });

              function pedido() 
              {
                return "${orderId}";
              }
              
              var insiteJSON = {
                "id" : "card-form",
                "fuc" : "999008881",
                "terminal" : "1",
                "order" : pedido(),
                "estiloInsite" : "inline",
                "idiomaInsite" : "ES",
                "mostrarLogo" : "true",
                "estiloReducido" : "false",
                "buttonValue" : "Pagar",
              }
              getInSiteFormJSON(insiteJSON);
          `}
              </script>
            </Helmet>
          </>
        )}
      </Box>
    );
  }
}

export default Payment;
