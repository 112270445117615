import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import {
  colFlex,
  displayOnMobile,
  flexBetween,
} from "../../styles/themes/commonStyles";
import Footer from "../../components/Layout/Footer/Footer";

const useStyles = makeStyles((theme) => ({
  imageLoader: {
    height: 350,
    position: "relative",
    zIndex: 1000,
    [theme.breakpoints.up("md")]: {
      borderRadius: "20px",
    },
  },
  titleLoader: {
    width: "60%",
    marginTop: "8px",
    height: "8%",
  },
  contentLoader: {
    width: "40%",
    marginTop: "8px",
    height: "5%",
  },
}));

function SkeletonRefugeDetailScreen() {
  const classes = useStyles();

  return (
    <Container disableGutters maxWidth="lg">
      <Box
        sx={{
          position: "relative",
          pb: 4,
        }}
      >
        <Box
          sx={{
            ...flexBetween,
            position: "relative",
          }}
        >
          <Box
            sx={{
              width: "100%",
              mb: 4,
            }}
          >
            <Grid container spacing={0} justify="flex-start">
              <Grid item s={12} order={{ xxs: 2, sm: 2 }}>
                <Box
                  sx={{
                    ...colFlex,
                    pt: { xxs: "24px", sm: "24px" },
                    pb: { xxs: "16px", sm: "0" },
                    pl: { xxs: "24px", sm: "40px" },
                    pr: { xxs: "24px", sm: "24px" },
                  }}
                >
                  <Skeleton className={classes.titleLoader} />
                  <Skeleton
                    animation="wave"
                    className={classes.contentLoader}
                  />
                </Box>
              </Grid>
              <Grid item s={12} order={{ xxs: 1, sm: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    pt: { xxs: 0, sm: "24px" },
                    pl: { xxs: 0, sm: "40px" },
                    pr: { xxs: 0, sm: "24px" },
                  }}
                >
                  <Box sx={{ width: 1, mb: "12px" }}>
                    <Skeleton
                      variant="rectangular"
                      height={350}
                      className={classes.imageLoader}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item s={12} order={{ xxs: 3, sm: 3 }}>
                <Box
                  sx={{
                    pl: { xxs: "24px", sm: "40px" },
                    pr: { xxs: "24px", sm: "24px" },
                    pt: { xxs: 0, sm: "40px" },
                    pb: { xxs: "24px", sm: "24px" },
                  }}
                >
                  <Skeleton
                    animation="wave"
                    className={classes.contentLoader}
                  />
                  <Skeleton
                    animation="wave"
                    className={classes.contentLoader}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          ...displayOnMobile,
          pb: { xxs: "80px", sm: "40px" },
        }}
      >
        <Footer />
      </Box>
    </Container>
  );
}

export default React.memo(SkeletonRefugeDetailScreen);
