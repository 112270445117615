import React from "react";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useTheme } from "@mui/styles";
import { useUIContext } from "../../../context/UIContext";
import { getCart } from "../../../store/modules/order/selectors";
import {
  flexBetween,
  roundWhiteIconShape,
} from "../../../styles/themes/commonStyles";
import WithHomeLink from "../../../components/Navigation/WithHomeLink";
import CartBadge from "../../../components/Layout/CartBadge";

function RefugeDetailScreenHeader() {
  const { page, setCartOpen } = useUIContext();
  const theme = useTheme();
  const cart = useSelector(getCart);

  const handleCartBadgeClick = () => {
    setCartOpen(true);
  };

  return (
    <Box
      sx={{
        zIndex: 1,
        background: "none",
        left: "0",
        padding: "25px 13px",
        position: "absolute",
        right: "0px",
        top: "0px",
        paddingTop: "16px",
        ...flexBetween,
        alignItems: "center",
        px: { xxs: "16px", sm: "40px" },
      }}
    >
      <Box sx={roundWhiteIconShape}>
        <WithHomeLink>
          <ArrowBackIosNewIcon
            sx={{
              fontSize: 20,
              mt: "6px",
            }}
          />
        </WithHomeLink>
      </Box>
      <CartBadge
        handleClick={handleCartBadgeClick}
        badgeContent={cart && cart.length}
      />
    </Box>
  );
}

export default React.memo(RefugeDetailScreenHeader);
