import { useTranslation } from "react-i18next";
import caRoutes from "../routes/ca.json";
import esRoutes from "../routes/es.json";
import enRoutes from "../routes/en.json";
import frRoutes from "../routes/fr.json";

const routesByLanguage = {
  ca: caRoutes,
  es: esRoutes,
  en: enRoutes,
  fr: frRoutes,
};

const useCustomRoutes = () => {
  const { i18n } = useTranslation();

  /**
   * Get the route mapping for the current language.
   * @returns {Object} - The routes object for the current language.
   */
  const getCurrentRoutes = () => {
    const currentLang = i18n.language || "en"; // Default to 'en'
    return routesByLanguage[currentLang] || routesByLanguage.en;
  };

  /**
   * Get the localized path for a given route key.
   * @param {string} key - The route key (e.g., "help", "cancellations").
   * @returns {string} - The localized path for the given key.
   */
  const getPath = (key) => {
    const routes = getCurrentRoutes();
    const path = routes[key];
    if (!path) {
      console.warn(`Route key "${key}" not found in current language routes.`);
      return "/";
    }
    return path;
  };

  return { getPath };
};

export default useCustomRoutes;
