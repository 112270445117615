import React from "react";
import { CaptionContainer, CaptionLegend, StatusContainer } from "./styles";
import cabinClosed from "Assets/images/map/cabin-closed.png";
import cabinFull from "Assets/images/map/cabin-full.png";
import cabinOpen from "Assets/images/map/cabin-open.png";
import cabinRestricted from "Assets/images/map/cabin-restricted.png";
import cabinMiddle from "Assets/images/map/cabin-middle.png";
import { useTranslation } from "react-i18next";

const _ = require("lodash");

function Caption() {
  const { t } = useTranslation("place");
  return (
    <CaptionContainer>
      <StatusContainer>
        <img width={22} src={cabinClosed} />
        <CaptionLegend>
          {_.capitalize(t("refuge.status.closed.label"))}
        </CaptionLegend>
      </StatusContainer>
      <StatusContainer>
        <img width={22} src={cabinOpen} />
        <CaptionLegend>
          {_.capitalize(t("refuge.status.open.label"))}
        </CaptionLegend>
      </StatusContainer>
      <StatusContainer>
        <img width={22} src={cabinFull} />
        <CaptionLegend>
          {_.capitalize(t("refuge.status.full.label"))}
        </CaptionLegend>
      </StatusContainer>
      <StatusContainer>
        <img width={22} src={cabinRestricted} />
        <CaptionLegend>
          {_.capitalize(t("refuge.status.restricted.label"))}
        </CaptionLegend>
      </StatusContainer>
      <StatusContainer>
        <img width={22} src={cabinMiddle} />
        <CaptionLegend>
          {_.capitalize(t("refuge.status.middle.label"))}
        </CaptionLegend>
      </StatusContainer>
    </CaptionContainer>
  );
}

export default Caption;
