import styled from "styled-components";

export const CookieConsentContent = styled.div`
  align-content: center;
  align-items: center;

  .CookieConsent {
    align-items: center !important;
    box-shadow: 0 8px 28px rgba(0, 0, 0, 0.28) !important;
    border-radius: 8px !important;
    padding: 20px !important;
    bottom: 20px !important;
    width: 90% !important;
    left: 50% !important;
    transform: translateX(-50%) !important;

    > div {
      margin-top: 0 !important;
    }
  }
`;

export const CookieConsentTitle = styled.h1`
  font-family: "Nunito sans", sans-serif;
  font-size: 1rem;
  margin-bottom: 8px;
  line-height: 1.25rem;
`;

export const CookieConsentText = styled.span`
  font-family: "Nunito sans", sans-serif;
  font-size: 14px;
  margin-right: 20px;
`;

export const CookieConsentLink = styled.a`
  color: #222 !important;
  font-weight: bold !important;
  text-decoration: underline !important;

  &:hover {
    text-decoration: underline;
  }
`;
