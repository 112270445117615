import * as Yup from "yup";

const checkoutValidationSchema = (t, checkoutFormModel) => {
  const {
    formField: {
      nif,
      firstName,
      lastName,
      email,
      phone,
      address1,
      city,
      zipcode,
      country,
      acceptDataAcceptance,
    },
  } = checkoutFormModel;

  return [
    Yup.object().shape({
      [nif.name]: Yup.string().required(t(nif.requiredErrorMsg)),
      [firstName.name]: Yup.string().required(t(firstName.requiredErrorMsg)),
      [lastName.name]: Yup.string().required(t(lastName.requiredErrorMsg)),
      [email.name]: Yup.string()
        .email(t("Please enter a valid email"))
        .required(t(email.requiredErrorMsg)),
      [phone.name]: Yup.string().required(t(phone.requiredErrorMsg)),
      [address1.name]: Yup.string().required(t(address1.requiredErrorMsg)),
      [city.name]: Yup.string().nullable().required(t(city.requiredErrorMsg)),
      [zipcode.name]: Yup.string()
        .required(t(zipcode.requiredErrorMsg))
        .test(
          "len",
          t(zipcode.invalidErrorMsg),
          (val) => val && val.length === 5
        ),
      [country.name]: Yup.string()
        .nullable()
        .required(t(country.requiredErrorMsg)),
      [acceptDataAcceptance.name]: Yup.boolean().oneOf(
        [true],
        t(acceptDataAcceptance.requiredErrorMsg)
      ),
    }),
  ];
};

export default checkoutValidationSchema;
