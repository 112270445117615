import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { orderService } from "../../modules/Order/OrderService";
import { colFlex, flexBetween } from "../../styles/themes/commonStyles";
import { getCart } from "../../store/modules/order/selectors";
import { formatPrice } from "../../utils/formatPrice";

function CartResumeCost() {
  const cart = useSelector(getCart);
  const orderPrice = orderService.calculateOrderPrice(cart);

  return (
    <Box
      sx={{
        p: "10px",
      }}
    >
      <Box
        sx={{
          ...flexBetween,
        }}
      >
        <Typography variant="bodyBold">Total</Typography>
        <Typography variant="subtitle1">
          {formatPrice(orderPrice.total)}
        </Typography>
      </Box>
      <Box
        sx={{
          ...flexBetween,
          my: "16px",
        }}
      >
        <Box
          sx={{
            ...colFlex,
            pr: "24px",
          }}
        >
          <Typography
            variant="bodyBold"
            sx={{
              mb: "12px",
            }}
          >
            Bestreta a pagar ara
          </Typography>
          <Typography variant="small">
            * La resta de l’import s’ha de pagar al refugi.
          </Typography>
          <Typography variant="small">
            ** Els preus que apareixen a la reserva són vàlids fins el 31 de
            Desembre de l’any en curs.
          </Typography>
          <Typography variant="small">
            *** Consulta les condicions per saber els mètodes de pagament al
            refugi.
          </Typography>
        </Box>
        <Typography variant="bodyBold">
          {formatPrice(orderPrice.deposit)}
        </Typography>
      </Box>
    </Box>
  );
}

export default React.memo(CartResumeCost);
