import React, { Component } from 'react';
import PropType from 'prop-types';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 40,
    width: '80vw',
  },
};

Modal.setAppElement(document.body);

class CustomModal extends Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidUpdate(nextProps) {
    const { isOpen } = this.props;
    if (!isOpen && nextProps.isOpen) {
      this.openModal();
    }
    if (isOpen && !nextProps.isOpen) {
      this.closeModal();
    }
  }

  openModal() {
    this.setState({ modalIsOpen: true });
    document.body.classList.add('modalOpen');
  }

  closeModal() {
    const { onCloseModal } = this.props;
    this.setState({ modalIsOpen: false });
    onCloseModal();
    document.body.classList.remove('modalOpen');
  }

  render() {
    const { renderContent } = this.props;
    const { modalIsOpen } = this.state;
    return (
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={this.closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {renderContent()}
      </Modal>
    );
  }
}

CustomModal.propTypes = {
  onCloseModal: PropType.func.isRequired,
  isOpen: PropType.bool.isRequired,
  renderContent: PropType.func.isRequired,
};

export default CustomModal;
