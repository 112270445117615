import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Dialog, Divider, IconButton, Slide } from "@mui/material";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { StyledActionLoadingButton } from "../../../styles/mui/StyledActionLoadingButton";
import { colFlex } from "../../../styles/themes/commonStyles";
import { useSearchContext } from "../../../context/SearchContext";
import BookIt from "../../../components/BookIt/BookIt";

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const useStyles = makeStyles((theme) => {
  const appbarHeight = 0;
  return {
    root: {
      zIndex: `1!important`,
      top: `${appbarHeight}px !important`,
      paddingTop: "12px",
      "& .MuiPaper-root": {
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px",
      },
    },
  };
});

function RefugeDetailScreenFooter({ scrollToRef, refuge, fetchRefuge }) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const {
    isLoadingSearch,
    setIsLoadingSearch,
    searchFilters,
    isLoadingProducts,
    setIsLoadingProducts,
  } = useSearchContext();
  const { peopleNumber, startDate, endDate } = searchFilters;
  const handleClose = () => {
    setIsOpen(false);
  };

  const goToProducts = () => {
    setIsOpen(false);
    scrollToRef();
  };

  return (
    <>
      {isOpen ? (
        <Dialog
          role="dialog"
          fullScreen
          className={classes.root}
          open={isOpen}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <Box
            sx={{
              ...colFlex,
              pb: "16px",
            }}
          >
            <Box
              sx={{
                pt: 2,
                px: 2,
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                px: "32px",
              }}
            >
              <BookIt
                handleSelectProducts={goToProducts}
                refuge={refuge}
                isLoadingProducts={isLoadingProducts}
                fetchRefuge={fetchRefuge}
              />
            </Box>
          </Box>
        </Dialog>
      ) : (
        <Paper
          id="stay-footer"
          sx={{
            display: "flex",
            position: "fixed",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            bottom: 0,
            py: "16px",
            px: { xxs: "24px", sm: "40px" },
            zIndex: 1999,
            backgroundColor: "white",
            borderTop: "1px solid",
            borderColor: (theme) => theme.palette.primary.main,
            height: (theme) => theme.global.mobileFooterHeight,
          }}
        >
          <Box style={{ display: "flex" }}>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              justifyContent="center"
              alignItems="center"
              spacing={0}
              sx={{
                p: 0,
              }}
            >
              <Box
                sx={{
                  ...colFlex,
                  alignItems: "center",
                  mr: "8px",
                }}
              >
                <Typography variant="body2" color="primary.dark">
                  Hostes
                </Typography>
                <u>
                  <Typography variant="bodyBold">{peopleNumber}</Typography>
                </u>
              </Box>
              <Box
                sx={{
                  ...colFlex,
                  alignItems: "center",
                  margin: "8px 16px 6px",
                }}
              >
                <Typography variant="body2" color="primary.dark">
                  {new Intl.DateTimeFormat("ca-ES", {
                    weekday: "long",
                  }).format(startDate)}
                </Typography>
                <u>
                  <Typography variant="bodyBold">
                    {new Intl.DateTimeFormat("ca-ES", {
                      day: "numeric",
                    }).format(startDate)}{" "}
                    {_.capitalize(
                      new Intl.DateTimeFormat("ca-ES", {
                        month: "short",
                      }).format(startDate)
                    )}
                  </Typography>
                </u>
              </Box>
              <Box
                sx={{
                  ...colFlex,
                  alignItems: "center",
                  margin: "8px 16px 6px",
                }}
              >
                <Typography variant="body2" color="primary.dark">
                  {new Intl.DateTimeFormat("ca-ES", {
                    weekday: "long",
                  }).format(endDate)}
                </Typography>
                <u>
                  <Typography variant="bodyBold">
                    {new Intl.DateTimeFormat("ca-ES", {
                      day: "numeric",
                    }).format(endDate)}{" "}
                    {_.capitalize(
                      new Intl.DateTimeFormat("ca-ES", {
                        month: "short",
                      }).format(endDate)
                    )}
                  </Typography>
                </u>
              </Box>
            </Stack>
          </Box>
          <StyledActionLoadingButton
            loading={isLoadingSearch}
            onClick={() => {
              setIsOpen(true);
            }}
            variant="text"
          >
            Reserva
          </StyledActionLoadingButton>
        </Paper>
      )}
    </>
  );
}

export default RefugeDetailScreenFooter;
