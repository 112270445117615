import {combineReducers} from "redux";
import refuges from "./modules/refuges/reducers";
import config from "./modules/config/reducers";
import ui from "./modules/ui/reducers";
import language from "./modules/language/reducers";
import order from "./modules/order/reducers";
import search from "./modules/search/reducers";
import modal from "./modules/modal/reducers";
import * as cookies from './modules/cookies/reducers'

const rootReducer = combineReducers({
  refuges,
  config,
  ui,
  language,
  order,
  search,
  modal,
  cookies: cookies.cookiesAppReducer,
});

export default rootReducer;
