import * as React from 'react';
import Badge from '@mui/material/Badge';
import { FaRoute } from 'react-icons/fa';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';

const useStyles = makeStyles((theme) => ({
  badge: {

  },
}));

export default function CartBadge({ handleClick, badgeContent }) {
  const classes = useStyles();
  return (
    <Button
      sx={{
        minHeight: '48px',
        backgroundColor: 'white',
        borderRadius: 10,
        border: '1px solid #ddd',
        padding: '8px',
        '&:hover': {
          boxShadow: '0 2px 4px rgba(0,0,0,0.18)',
          cursor: 'pointer',
        },
      }}
      onClick={handleClick}
    >
      <Badge
        badgeContent={badgeContent}
      >
        <FaRoute
          size={20}
        />
      </Badge>
    </Button>
  );
}
