export const COLORS = {
  white: '#fff',
  black: '#000',
  refusTextBlack: '#222222',
  refusTextGrey: '#757575',
  refusLightGrey: '#E0E0E0',
  refusGreen: '#003D00',
  refusLightGreen: '#8BC34A',
  refusSoftGreen: '#F1F8E9',
  refusButtonGrey: '#F8F7FA',
  errorBackground: '#F29682',
  errorText: '#fff',
  warningBackground: '#EAEB5E',
  warningText: '#666804',
  noticeBackground: '#82C2F2',
  noticeText: '#fff',
  dark: '#0e1b20',
  light: '#aaa',
  muted: '#abafb3',
  border: '#DDDDDD',
  inverse: '#2F3D4A',
  shaft: '#333',
  success: '#4CAF50',
  info: '#00a2ff',
  danger: '#FF5722',
  warning: '#FFC107',
  /// ////////////
  // Grays
  /// ////////////
  dim_grey: '#696969',
  dove_gray: '#d5d5d5',
  body_bg: '#f3f6f9',
  light_gray: 'rgb(230,230,230)',
  primary: {
    light: '#F7F7F7',
    main: '#DDDDDD',
    dark: '#717171',
  },
  secondary: {
    light: '#8BC34A',
    main: '#003D00',
    dark: '#001a00',
  },
  neutral: {
    main: '#222222',
    contrastText: '#fff',
  },
};

export const Gradiants = {
  Primary: ['#FF4E6A', '#b4414c'],
};
